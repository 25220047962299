<template>
  <mks-heading class="mks-component heading-with-bean" :tag="tag" :tagClass="tagClass" :color="colorText">
    {{textBefore}}<DisplayBean v-if="textBean" class="bean heading__bean" :text="textBean" :color="customColorBean ? customColorBean : colorBean" tag="span"></DisplayBean>{{textAfter}}
  </mks-heading>
</template>
<script>
export default {
  props: {
    textBefore: {
      type: String,
      default: ''
    },
    textBean: {
      type: String,
      default: ''
    },
    textAfter: {
      type: String,
      default: ''
    },
    colorText: {
      type: String,
      default: 'primary'
    },
    colorBean: {
      type: String,
      default: 'secondary'
    },
    customColorBean: {
      type: String,
      default: ''
    },
    tag: {
      type: String,
      default: 'h2'
    },
    tagClass: {
      type: String,
      default: 'h2'
    }
  }
}
</script>
<style lang="scss">
.mks-component.heading-with-bean {
  line-height: 1.3 !important;
  &.h1, &.h2 {
    // font-weight: 900 !important;
  }
}

.heading__bean {
  position: relative;
  top: -1px;
  line-height: 1 !important;
}
</style>