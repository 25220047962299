export default defineNuxtPlugin((NuxtApp) => {
  let importedToastedFn = null;
  let importToastPromise = null;
  const importToastFn = async () => {
    const Toasted = await import("vue-toasted");
    const Vue = NuxtApp.vueApp;
    const prevPrototype = Vue.prototype
    Vue.prototype = {};
    Vue.use(Toasted.default);
    importedToastedFn = Vue.prototype.$toasted;
    Vue.prototype = prevPrototype;
  }
  return {
    provide: {
      toasted: {
        async show(...params) {
          if (!importToastPromise) {
            importToastPromise = importToastFn();
          }
          await importToastPromise;
          importedToastedFn.show(...params);
        },
        async clear(...params) {
          if (!importToastPromise) {
            importToastPromise = importToastFn();
          }
          await importToastPromise;
          importedToastedFn.clear(...params);
        }
      }
    }
  }
})



