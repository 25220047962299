<template>
  <div class="event__cover-wrapper">
    <component
      :is="eventLink ? 'router-link' : 'span'"
      :to="eventLink || undefined"
      :target="target"
      class="event__cover"
    >
      <EventCardCoverPreview :event="event" v-if="showCoverPreview"/>
      <nuxt-img
        v-else
        format="webp"
        sizes="xs:100vw sm:100vw md:100vw lg:500px xl:600px"
        :src="imageUrl"
        :alt="event.title ? event.title[event.lang] : ''"
        loading="lazy"
        :class="
          `cover__image`
        "
      />
      <!--nuxt-img
        v-if="event.source && event.source.name === 'helloasso'"
        :src="imageUrl"
        :alt="event.title ? event.title[event.lang] : ''"
        format="webp"
        sizes="xs:100vw sm:100vw md:100vw lg:500px xl:600px"
        loading="lazy"
        class="cover__image--contain"
      /-->
      <div class="cover__overlay">
        <mks-tag
          v-if="false"
          tag="span"
          class="tag__makesense"
          variant="ghost"
          size="small"
        >
          <span class="logo__makesense"><span></span></span>
          {{ __("organisé par makesense") }}</mks-tag
        >
        <!--mks-tag
          v-if="event.source && event.source.name === 'helloasso'"
          tag="span"
          class="tag__makesense"
          size="small"
        >
          via
          <img
            src="/assets/images/helloasso.png"
            class="logo_partner"
            :alt="__('via helloasso')"
        /></mks-tag-->
      </div>
      <!-- <div class="overlay__full" v-if="availableTickets == 0">
        <mks-text size="large" weight="bold" align="center">{{__('Complet')}}</mks-text>
      </div> -->
    </component>
    <slot></slot>
  </div>
</template>
<script>
import EventCardCoverPreview from './CoverPreview.vue';
export default {
  props: {
    event: Object,
    eventLink: {
      type: [Boolean, String],
      default: false
    },
    showCoverPreview: {
      type: Boolean,
      default: false
    },
    target: {
      type: String,
      default: '_self'
    }
  },
  components: {EventCardCoverPreview},
  computed: {
    imageUrl() {
      if (
        this.event.cover &&
        this.event.cover[this.event.lang] &&
        this.event.cover[this.event.lang].url
      ) {
        return this.checkURL(this.event.cover[this.event.lang].url)
          ? this.event.cover[this.event.lang].url
          : "/placeholder.png";
      }
      return "/placeholder.png";
    },
  },
  methods: {
    checkURL(url) {
      return url.match(/\.(jpeg|jpg|gif|png)$/i) != null;
    },
  }
}
</script>
<style lang="scss" scoped>
.event__cover {
  display: block;
  height: auto;
  border-radius: $border-radius-l;
  overflow: hidden;
  transition: inherit;
  background: $color-neutral-90;
  @include ratio(16 9);

  .event-card--ticket & {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    @include breakpoint("small") {
      border-bottom-left-radius: $border-radius-l;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .cover__image {
    min-height: 100%;
    object-fit: cover;

    &--blurry {
      filter: blur(3px);
    }

    &--contain {
      position: absolute;
      object-fit: contain;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &:hover {
    //background: $color-neutral-90;
    @include elevated;
    // border-radius: $border-radius-l;
  }
}



.cover__overlay {
  position: absolute;
  top: $space-s;
  left: $space-s;
  width: 100%;
  height: 100%;
}

.tag__makesense {
  .logo__makesense {
    background: $color-default;
    width: $space-s;
    height: $space-s;
    border-radius: $border-radius-circle;
    text-align: center;
    margin-right: $space-xxs;

    span {
      @include bean($color-secondary);
      width: 9px;
      margin-right: 0;
      margin-bottom: 1px;
    }
  }
}
.logo_partner {
  max-height: $space-m;
}
</style>
