<template>
  <nav class="mks-breadcrumbs mks-component" :style="colorStyles">
    <ol 
      class="mks-breadcrumbs__list" 
      vocab="http://schema.org/"
      typeof="BreadcrumbList">
      <li 
        property="itemListElement" 
        typeof="ListItem" 
        v-for="(item, index) in items" 
        :key="item.label" 
        :class="{
          'mks-breadcrumbs__item': true,
          'mks-breadcrumbs__item--current': route === item.path
        }">
        <span property="item" class="mks-breadcrumbs__link">
          <span v-if="!item.path || route === item.path" property="name" typeof="WebPage" aria-current="location" type="menu">{{ item.label }}</span>
          <mks-link v-else :to="item.path" property="name" typeof="WebPage" type="menu" class="breadcrumb__link">{{ item.label }}</mks-link>
        
        </span>
        <mks-icon v-if="index < items.length - 1" class="mks-breadcrumbs__list-divider" type="chevron-right" aria-hidden="true" size="16"/>
        <meta property="position" :content="index + 1">
      </li>
    </ol>
  </nav>
</template>
<script>
import colorsMixin from '@/mixins/colors'
export default {
  mixins: [colorsMixin],
  data() {
    const route = useRoute();
    return {
      route: route.path
    }
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    color:{
      type: String,
      default: "#808080"
    }
  },
  computed: {
    colorStyles() {
      if (!this.color) return;
      return this.getColorStyles('color', this.color);
    }
  }
}
</script>
<style lang="scss">

.mks-breadcrumbs__list {
  // display: flex;
  // flex-wrap: wrap;
  // align-items: baseline;
}

.mks-breadcrumbs__item {
  // todo: replace theme;
  @include text-small-semibold;
  color: inherit;
  display: inline;
}

.mks-breadcrumbs__item .router-link-exact-active {
  opacity: 0.8;
}

.mks-breadcrumbs__list-divider {
  margin-left: $space-xxs;
  margin-right: $space-xxs;
}

.breadcrumb__link:hover {
  color: inherit !important;
  opacity: 0.8;
}

</style>