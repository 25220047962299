export const state = () => ({
  conditions: [],
  pillars: [],
});

export const getters = {
  get: state => {
    return state.conditions;
  },
  getCondition: state => {
    return id => state.conditions[id];
  },
  getPillars: state => {
    return state.pillars;
  },
  getPillar: state => {
    return id => state.pillars[id];
  }
};

export const mutations = {
  SET_CONDITIONS(state, payload) {
    state.conditions = payload;
  },
  SET_PILLARS(state, payload) {
    state.pillars = payload;
  },
};

export const actions = {
  async init(state) {
    const [conditionsFromBuilder, pillarsFromBuilder] = await Promise.all([
      this.$builder.getAll('condition'),
      this.$builder.getAll('pilier')
    ]);
    const conditionsMap = conditionsFromBuilder.results.map(item => {
      return [item.id, item.data.value]
    });

    const orderedPillars = pillarsFromBuilder.results.sort((a, b) => { return a.data.order.toString() > b.data.order.toString() ? 1 : -1})

    const pillarsMap = orderedPillars.map(item => {
      return [item.id, item.data]
    });

    const conditions = Object.fromEntries(conditionsMap);
    const pillars = Object.fromEntries(pillarsMap);

    state.commit('SET_CONDITIONS', conditions);
    state.commit('SET_PILLARS', pillars);
  },
};
