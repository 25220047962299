let getCookie = function (name) {
  if (process.client) {
    let v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
  }
  return null;
};

export const state = () => ({
  email: null,
  profile: null,
  loading: true,
  loggedIn: false
});

export const getters = {
  getEmail: state => {
    return state.email;
  },
  isLoggedIn: state => {
    return state.loggedIn;
  },
  userLoading: state => {
    return state.loading;
  },
  profile: state => {
    return state.profile;
  }
};

export const mutations = {
  SET_EMAIL(state, email) {
    if (!process.client) return;
    if (!getCookie('makesense_events_email') || getCookie('makesense_events_email') !== email) {
        document.cookie = `makesense_events_email=${email}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
    }
    state.email = email;
  },
  SET_PROFILE(state, profile) {
    if (!process.client) return;
    state.profile = profile;
    state.loggedIn = !!(profile && profile._id);
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  RESET(state) {
    state.email = null;
    state.profile = null;
    state.loading = false;
    state.loggedIn = false;
  }
};

export const actions = {
  async init(state) {
    const emailCookie = getCookie('makesense_events_email');
    if (emailCookie) state.dispatch('setEmail', emailCookie);
    state.dispatch('getProfile');
  },
  setEmail(state, email) {
    state.commit('SET_EMAIL', email);
    state.dispatch('analytics/identify', email, {root:true});
  },
  async getProfile(state, loadState = true) {
    if (loadState) state.commit('SET_LOADING', true);
    if (this.$api.Auth.hasToken()) {
      try {
        const profile = await this.$api.User.me();
        await state.dispatch('setProfile', profile);
      }
      catch (e) {
        state.dispatch('reset');
      }
    }
    if (loadState && process.client) state.commit('SET_LOADING', false);
  },
  setProfile(state, profile) {
    if (!process.client) return;
    state.commit('SET_PROFILE', profile);
    state.dispatch('analytics/identify', profile.email, {root:true});
  },
  async updateUser(state, updatedFields) {
    const user = {
      ...state.getters.profile,
      ...updatedFields
    }

    try {
      const updated = await this.$api.User.update(user);
      state.dispatch('setProfile', updated);
      return true;
    }
    catch (e) {
      console.log(e);
      return false;
    }

  },
  logout(state) {
    state.dispatch('reset');
    this.$api.Auth.logout();
  },
  reset(state) {
    state.commit('RESET');
  }
}
