
export const state = () => ({
  attended: [],
  loadingAttend: null,
  event: [],
  filteredEvents: []
});

export const mutations = {
  SET_ATTENDED(state, id) {
    state.attended.push(id);
  },
  SET_LOADING_ATTEND(state, id) {
    state.loadingAttend = id;
  },
};


export const actions = {
    async attend(store, {event, email, user}) {
      // state.commit.SET_ATTEND(id);
      store.dispatch('user/setEmail', email, {root:true});

      return await this.$api.Events.attend(event._id, {
        user: user,
        email: email,
        transactionnal: true,
        lang: this.$i18n.locale.value ? this.$i18n.locale.value : this.$i18n.locale
      })
      .then((data) => {
        store.commit('SET_ATTENDED', event._id);
        return 'ATTENDED';
      });
    },
    async subAndAttend(store, {event, value}) {
      return await this.$api.Events.subAndAttend(event._id, value).then((res) => {
        store.commit('SET_ATTENDED', event._id);
        return res;
      })
    }
  };
