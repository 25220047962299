<template>
  <mks-heading 
    class="mks-component heading-bean"
    :tag="tag" 
    :tag-class="tagClass" 
    :style="style"
    :color="color">
    <span v-if="text" class="heading-bean__inner" v-html="text">
    </span>
    <span v-else class="heading-bean__inner">
      <slot></slot>
    </span>
    
  </mks-heading>
</template>
<script>
import colorsMixins from '@/mixins/colors';
export default {
  mixins: [colorsMixins],
  props: {
    tag: {
      type: String,
      default: 'h3'
    },
    tagClass: {
      type: String,
      default: 'h3'
    },
    weight: {
      type: String,
      default: 'extrabold'
    },
    background: {
      type: String,
      default: 'default' // hexa or DS color variable
    },
    color: {
      type: String,
      default: 'inherit'
    },
    text: {
      type: String
    }
  },
  computed: {
    style() {
      let style = `font-weight: var(--font-weight-${this.weight}) !important;`;
      if (this.background) {
        style += this.getColorStyles('--heading-bean-background', this.background)
      }
      return style;
    },
  }
}
</script>
<style lang="scss">

.heading-bean__inner {
  --color-bg: #34304c;
  --color-bg2: #534d7a;
  --color-highlight: #fff;
  font: inherit;
  font-weight: $font-weight-bold !important;
  border-radius: $border-radius-xl;
  padding: $space-xxs $space-s;
  background-color: var(--heading-bean-background);
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  line-height: 1.34;
}
</style>