
// import Vue from 'vue'

export const state = () => ({
  open: [],
  props: {}
});

export const getters = {
  active: state => {
    return (state.open.length > 0 ? state.open[0] : null);
  },
  allOpen: state => {
    return state.open;
  },
  props: state => {
    return state.props;
  },
};

export const mutations = {
  OPEN(state, name) {
    state.open.unshift(name);
  },
  CLOSE(state, name) {
    const index = state.open.indexOf(name)
    if (index !== -1) {
      state.open.splice(index, 1);
    }
    // Vue.delete(state.open, state.open.indexOf(name));
  },
  UPDATE_PROPS(state, {name, props}) {
    state.props[name] = props;
    // Vue.set(state.props, name, props);
  }
};

export const actions = {
  open(state, {name, props}) {
    state.commit('OPEN', name);
    state.commit('UPDATE_PROPS', {name, props});
  },
  close(state, name) {
    state.commit('CLOSE', name);
    state.commit('UPDATE_PROPS', name, undefined);
  }
};
