<template>
  <span 
    class="mks-component chiche-tag" 
    :class="{
      'chiche-tag--background': background
    }"
    :style="styles"
  >
    <IconContent v-if="icon" :type="icon"></IconContent>
    <span>
      <slot></slot>
    </span>
  </span>
</template>
<script>

import colorsMixin from '@/mixins/colors'
export default {
  mixins: [colorsMixin],
  props: {
    icon: {
      type: String,
    },
    iconStroke: {
      type: String,
    },
    color: {
      type: String,
      default: 'primary' // hexa code or DS variable (without color-)
    },
    background: {
      type: [String, Boolean],
      default: false
    }
  },
  mounted() {
    if (!this.icon) return;
  },
  computed: {
    styles() {
      let style = '';
      // `--tag-color: ${colorTheme}; --tag-background: ${backgroundTheme}`
      if (this.color) {
        style += this.getColorStyles('--tag-color', this.color);
      }
      if (this.background) {
        style += this.getColorStyles('--tag-background', this.background);
      }
      return style
    }
  }
}
</script>
<style lang="scss">
.chiche-tag {
  @include text-body-black;
  font-family: $font-heading;
  font-weight: 900;
  text-transform: uppercase;
  color: var(--tag-color);
  display: flex;
  align-items: center;

  &--background {
    padding: $space-xxs $space-s;
    background: var(--tag-background)
  }

  svg {
    margin-right: $space-xxs;
  }
}

.chiche-tag--background {
  padding: $space-xxxs $space-xs;
  background: var(--tag-background);
  border-radius: $border-radius-l;
  display: inline-flex;
}
</style>