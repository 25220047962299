var grantedGDPR = function() {
    if (process.server) return false;
    const getGDPR = localStorage.getItem("GDPR:accepted");
    return typeof getGDPR !== "undefined" && getGDPR === "true";
};

export const state = () => ({
  accepted: false,
  buffer: []
});

export const getters = {
  getBuffer: state => {
    return state.buffer;
  }
};

export const mutations = {
  SET_ACCEPTED(state, boolean) {
    state.accepted = boolean;
  },
  ADD_TO_BUFFER(state, event) {
    state.buffer.push(event);
  }
};

export const actions = {
  init(state) {
    if (process.client) this.$initGaAnon();
    if (grantedGDPR()) {
      state.commit('SET_ACCEPTED', grantedGDPR());
      this.$initSegment();
    }
  },
  accept(state) {
    localStorage.setItem("GDPR:accepted", true);
    localStorage.setItem("GDPR:date", new Date());
    state.commit('SET_ACCEPTED', true);
    this.$initSegment();
  },
  identify(store, data) {
    if (!grantedGDPR() || !window || !window.analytics) return false;
    window.analytics.identify(data);
  },
  page({ commit, getters, rootGetters }, data) {
    if (!grantedGDPR() || !window || !window.analytics) return false;
    window.analytics.page(data);
  },
  track({ commit, getters, rootGetters }, { event, data }) {
    if (window && window._paq) {
      window._paq.push(['trackEvent', 'engagement', event, 'not set', data]);
    }
    if (!grantedGDPR() || !window || !window.analytics)
      return commit("ADD_TO_BUFFER", {
        event: event,
        data: data
      });
    window.analytics.track(event, data);
  },
  playBuffer({ dispatch, getters, rootGetters }) {
    var promises = [];
    getters.getBuffer.forEach(savedEvent => {
      promises.push(dispatch("track", savedEvent));
    });

    return Promise.all(promises);
  }
};
