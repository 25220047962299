<template>
  <Section :background="background" :color="textColor" smallContent radius="bean">
    <div class="mks-grid nl__content">
      <div class="mks-col-6@sm">
        <nuxt-img 
          v-if="image"
          class="nl-hero__image "
          :src="image" 
          alt="le cachet du lundi" 
          format="webp"
          sizes="xs:250px sm:300px lg:500px"
          loading="lazy">
        </nuxt-img>
      </div>
      
      <mks-spacer class="mks-col-6@sm nl-hero__content">
        <Heading 
          tag="h2" 
          color="inherit" 
          class="nl-hero__heading"
          :textBefore="textBefore"
          :textBean="textBean"
          :textAfter="textAfter"
          >
        </Heading>
        <mks-text v-if="description" tag="p" v-html="description" class="nl-hero__description"/>
        <NewsletterField class="nl-hero__form" :sign-up-source="source" size="big"/>
      </mks-spacer>
    </div>
    
  </Section>
</template>
<script>

export default defineNuxtComponent({
  props: {
    background: {
      type: String,
      default: 'parsley'
    },
    textColor: {
      type: String,
      default: 'neutral-100'
    },
    source: {
      type: String
    },
    image: {
      type: String,
      default: 'assets/images/newsletter-big.png'
    },
    textBefore: {
      type: String,
      default: 'La '
    },
    textBean: {
      type: String,
      default: 'ChicheLetter'
    },
    textAfter: {
      type: String,
      default: ', pour des lundis aussi excitants qu’un lama en rollers'
    },
    description: {
      type: String,
      default: 'Tous les lundis, reçois un shoot d’infos, de rendez-vous et d’actions pour tenir toute la semaine.'
    },
    callToAction: {
      type: String,
      default: 'S\'abonner' // TODO LANG car utilisé sur events
    },
  },
});
</script>
<style lang="scss" scoped>
.nl__content {
  &:first-child {
    @include breakpoint('small', 'max') {
      text-align: center;
    }
  }

  @include breakpoint('small') {
    padding-top: $space-xxl;
  }

  @include breakpoint('medium') {
    padding-top: $space-xxxl;
  }
}
.nl-hero__image {
  max-width: 150px;
  margin-bottom: $space-l;

  @include breakpoint('small') {
    max-width: 320px;
    margin-bottom: 0;
  }
}
.nl-hero__content {
  align-self: center;
  
}
</style>
