export default defineNuxtPlugin((NuxtApp) => {
  const init = () => {
    if (process.client && NuxtApp.$env.env !== "development") {
      console.log('installing 🍪 ...');
      var getGDPR = localStorage.getItem("GDPR:accepted");

      if (typeof getGDPR !== "undefined" && getGDPR === "true") {
        setTimeout(function() {
          let script = document.createElement("script");
          let jsCode = `
          !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.13.1";
          analytics.load("${NuxtApp.$env.segment.writeKey}");
          // analytics.page();
          const event = new Event('segment_loaded');
          window.dispatchEvent(event);
          }}();`;
          script.async = true;
          script.id = "segment";
          script.appendChild(document.createTextNode(jsCode));
          window.addEventListener(
            "segment_loaded",
            function(e) {
              NuxtApp.$store.dispatch("analytics/playBuffer");
            },
            false
          );
          document.head.appendChild(script);
        }, 0);

        setTimeout(function() {
          const id = 2546244;
          let script = document.createElement("script");
          let jsCode = `
            (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:${id},hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
          script.async = true;
          script.id = "hotjar";
          script.appendChild(document.createTextNode(jsCode));
          document.head.appendChild(script);
        }, 0);
        
        if (NuxtApp.$env.clarity?.id) {
          setTimeout(function () {
            const script = document.createElement("script");
            const jsCode = `(function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "${NuxtApp.$env.clarity.id}");`;
            script.async = true;
            script.id = "ms-clarity";
            script.appendChild(document.createTextNode(jsCode));
            document.head.appendChild(script);
          }, 0);
        }

        // setTimeout(function() {
        //   let script = document.createElement("script");
        //   let jsCode = `
        //   (function (s,c,r,ee,b) {
        //          s['ScreebObject']=r;s[r]=s[r]||function(){(s[r].q=s[r].q||[]).push(arguments)};
        //          b=c.createElement('script');b.type='text/javascript';
        //          b.id=r;b.src=ee;b.async=1;c.getElementsByTagName("head")[0].appendChild(b);
        //        }(window,document,'$screeb','https://t.screeb.app/tag.js'));

        //        $screeb('init', '3502f664-64d3-4484-b226-88d7ba5e4d04');`;
        //   script.async = true;
        //   script.id = "screeb";
        //   script.appendChild(document.createTextNode(jsCode));
        //   document.head.appendChild(script);
        // }, 0);
      }
    }
  }

  const initGaAnon = () => {
    if (process.client && NuxtApp.$env.env === "development") {
      return;
    }
    console.log('adding gtag anonyme...')
    // here add gtag anonymisé
    setTimeout(function() {
      let script = document.createElement("script");
      script.async = true;
      script.id = "ga-anonyme";
      script.src = "https://www.googletagmanager.com/gtag/js?id=G-ZDQRDCK6XR"
      document.head.appendChild(script);
    }, 0);
    setTimeout(function() {
      let script = document.createElement("script");
      script.async = true;
      script.id = "ga-anonyme-2";
      let jsCode = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-ZDQRDCK6XR', { 'anonymize_ip': true });`;
      script.appendChild(document.createTextNode(jsCode));
      document.head.appendChild(script);
    }, 0);
  }

  NuxtApp.$router.afterEach(function (to, from) {
    // console.log(to);
    // analytics page
    NuxtApp.$store.dispatch('analytics/page');
  });

  NuxtApp.$store.$initGaAnon = initGaAnon;
  NuxtApp.$store.$initSegment = init;

  return {
    provide: {
      initGaAnon: initGaAnon,
      initSegment: init
    }
  };
});
