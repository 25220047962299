// import type { RouterConfig } from '@nuxt/schema';
// https://router.vuejs.org/api/interfaces/routeroptions.html
export default {
  async scrollBehavior(to, from, savedPosition) {
    if (to.path === from.path) {
      return;
    }
    else if (window.location.hash) {
      return;
    }

    let scrollTo = {top: 0};
    
    if (to.meta && to.meta.keepalive && savedPosition) {
      scrollTo = savedPosition;
    }

    return new Promise((resolve) => {
      setTimeout(() => resolve(scrollTo), 520);
    });
  }
};
