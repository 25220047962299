export default {
  methods: {
    getColorStyles(property, value) {
      if (!value) return;
      if (value.startsWith('rgb') || value.startsWith('#' || value === 'inherit' || value === 'initial')) {
        return `${property}: ${value};`
      }
      else if (value.startsWith('color-')) {
        return `${property}: var(--${value});`
      }
      else return `${property}: var(--color-${value});`
    }
  }
}