<script>
import { h, mergeProps, markRaw } from 'vue';
import { REGISTERED_COMPONENTS } from '@/init-builder.ts';
import { Button, Columns, Image, Section, Text, isPreviewing, Content as BuilderContent } from '@builder_sdk';
import Embed from '@/components/organisms/RenderContent/Embed.vue';
import CustomCode from '@/components/organisms/RenderContent/CustomCode.vue';
const breakpointNames = ['large', 'medium', 'small'];

function kebabCase(str) {
  return str.replace(/(?!^)([A-Z\u00C0-\u00D6])/g, function (match) {
    return '-' + match.toLowerCase();
  });
}

function renderBlockRecursive (block, disableStyles) {
  const parentStyles = {
    large: [],
    medium: [],
    small: []
  };
  const renderedChildren = [];
  if (block?.children) {
    block.children.forEach((childBlock) => {
      const { styles, rendered } = renderBlockRecursive(childBlock, block?.component?.name === 'Carousel');
      breakpointNames.forEach((breakpoint) => {
        parentStyles[breakpoint] = parentStyles[breakpoint].concat(styles[breakpoint]);
      });
      renderedChildren.push(rendered);
    });
  }
  let matchedComponent;
  if (!disableStyles) {
    breakpointNames.forEach((breakpoint) => {
      if (block?.responsiveStyles?.[breakpoint]) {
        parentStyles[breakpoint].push([block.id, block.responsiveStyles[breakpoint]]);
      }
    });
    if (block?.animations?.length) {
      const hoverAnimation = block.animations.find((anim) => anim.trigger === 'hover');
      if (hoverAnimation && hoverAnimation?.steps?.length > 1 && hoverAnimation.steps[1].styles) {
        parentStyles.large.push([block.id + ':hover', {
          ...hoverAnimation.steps[1].styles,
        }]);
        parentStyles.large.push([block.id, {
          'transition-property': Object.keys(hoverAnimation.steps[1].styles).map((property) => kebabCase(property)).join(', '),
          'transition-delay': hoverAnimation.delay + 's',
          'transition-duration': hoverAnimation.duration + 's'
        }]);
      }
    }
  }

  let options = block?.component?.options || {};
  if (block?.component?.name) {
    matchedComponent = REGISTERED_COMPONENTS.find((registeredComponent) => {
      return registeredComponent.name === block.component?.name;
    });
    
    if (!matchedComponent) {
      if (block.component.name === 'Text') {
        matchedComponent = {component: Text};
      }
      else if (block.component.name === 'Image') {
        matchedComponent = {component: Image};
        Object.entries(block.component.options).forEach(([key]) => {
          if (!Image.props.includes(key)) {
            delete block.component.options[key];
          }
        })
      }
      else if (block.component.name === 'Core:Button') {
        matchedComponent = {component: Button, wrapper: false};
      }
      else if (block.component.name === 'Core:Section') {
        matchedComponent = {component: Section, wrapperClasses: true};
      }
      else if (block.component.name === 'Custom Code') {
        matchedComponent = {component: CustomCode};
      }
      else if (block.component.name === 'Embed') {
        matchedComponent = {component: Embed};
      }
      else if (block.component.name === 'Columns') {
        
        const componentsInfos = Object.fromEntries(REGISTERED_COMPONENTS.map((component) => [component.name, {component: component.component}]));
        componentsInfos['Core:Button'] = {component: Button};
        componentsInfos.Columns = {component: Columns};
        componentsInfos.Embed = {component: Embed};
        componentsInfos.Image = {component: Image};
        componentsInfos['Core:Section'] = {component: Section};
        componentsInfos.Text = {component: Text};
        matchedComponent = {component: Columns};
        options = {
          ...options,
          builderBlock: block,
          builderContext: {},
          builderComponents: markRaw(componentsInfos),
        }
/**/
      }
      else {
        console.log('NOT MATCHED', block.component.name, block.component.options)
      }
    }
  }
  else if (block?.tagName) {
    matchedComponent = {
      component: block.tagName,
      wrapper: false
    }
    options = block.properties || {};
  }
  if (options.builder) {
    options.builder = undefined;
  }
  let rendered;
  let classOptions = {};
  if (!matchedComponent?.noClass) {
    classOptions = {class: [block.id, 'builder-block']};
  }
  if (block.class) {
    classOptions = mergeProps(classOptions, {class: block.class});
  }
  if (matchedComponent?.component) {
    let childrenParam;
    if (typeof matchedComponent.component === 'string') {
      childrenParam = renderedChildren;
    }
    else {
      childrenParam = () => renderedChildren;
    }
    
    if (matchedComponent.wrapper === false) {
      rendered = h(matchedComponent.component, mergeProps(options, classOptions), childrenParam);
    }
    else {
      rendered = h(
          block.linkUrl ? 'a' : (block.tagName ? block.tagName : 'div'),
          mergeProps(classOptions, block.properties),
          h(
              matchedComponent.component,
              matchedComponent.wrapperClasses
                  ? mergeProps(options, classOptions)
                  : options,
              childrenParam
          )
      );
    }
  }
  else {
    rendered = h('div', mergeProps(options, classOptions), renderedChildren);
  }
  return {
    styles: parentStyles,
    rendered: rendered
  }
}

function renderStyleBlock ([id, styles]) {
  return `.${id} {${Object.entries(styles).map(([prop, value]) => {
    return `${kebabCase(prop)}: ${value};`;
  }).join('')}}`;
}

const modifiedArticlesPaths = [
  '/10-jeux-de-societe-ecolos-ou-presque-a-tester-pendant-les-fetes',
  '/etes-vous-prets-a-vivre-en-eco-lieu',
  '/les-velos-ces-feministes-engages',
  '/zad-zone-a-definir-pour-enfin-comprendre',
  '/ok-google-comment-on-trouve-un-emploi-vert-dans-les-quartiers-prioritaires',
  '/vivre-ensemble-d-issoire-au-matin',
  '/13-personnes-qui-attaquent-le-monde-a-coups-de-poesie',
  '/10-manieres-inattendues-de-sensibiliser-ses-proches',
  '/ces-assos-qui-aident-les-personnes-sans-papiers',
  '/retrospective-2023-les-10-plus-ou-moins-bonnes-nouvelles-pour-la-planete',
  '/les-limites-planetaires-expliquees-on-pousse-la-terre-un-peu-trop-loin-maurice',
  '/10-chiffres-sur-la-precarite-alimentaire',
  '/top-20-de-citations-de-femmes-ultra-badass',
  '/il-ny-a-pas-dage-pour-partir-en-classe-verte',
  '/ep-9-que-faire-de-mes-cheveux-blancs',
  '/ep-10-gentrification-et-bonnes-intentions',
  '/ep-11-les-repas-de-famille',
  '/lettre-a-un-futur-etre-humain',
  '/et-vous-votre-ombre-climatique-ca-dit-quoi',
  '/10-assos-qui-viennent-en-aide-aux-victimes-de-la-guerre',
  '/leffet-matilda-celui-qui-a-empeche-les-femmes-de-ramener-leur-science',
  '/tout-ce-que-vous-avez-toujours-voulu-savoir-sur-limmigration-sans-jamais-oser-le-demander',
  '/aurore-lhoroscope-climato-social-2024-le-vrai-le-seul-lunique',
  '/tuto-pour-vieillir-avec-son-temps',
  '/8-manieres-de-retrouver-du-temps-quand-on-est-presse-comme-un-citron',
  '/survivre-en-2024-avec-un-seul-objet-fais-le-test',
  '/faire-des-enfants-quand-tout-fout-le-camp-la-crise-environnementale-menace-notre-fertilite'
];

export default {
  props: {
    content: Object,
    model: String
  },
  mounted () {
    this.$el.addEventListener('click', this.onClick);
  },
  computed: {
    newStyles() {
      let createdDate = this.content.createdAt || this.content.createdDate;
      return this.$dayjs(createdDate).isAfter('2024-03-21') || modifiedArticlesPaths.includes(this.content.data.url);
    }
  },
  methods: {
    onClick (e) {
      const target = e.target;
      if (target.tagName === 'A') {
        const href = target.getAttribute('href');
        if (href.match(/^#/)) {
          const scrollElement = document.querySelector(href);
          if (scrollElement) {
            e.preventDefault();
            const box = scrollElement.getBoundingClientRect();
            const currentScroll = document.documentElement.scrollTop || 0;
            window.scrollTo({
              top: currentScroll + box.top,
              behavior: 'smooth'
            });
          }
        }
      } 
    }
  },
  render () {
    const newStyles = this.newStyles;
    if (this.$route?.query?.previewing === 'true' || isPreviewing()) {
      return h('div', {class: newStyles ? '--title-spacing' : ''}, h(BuilderContent, {
        content: this.content,
        customComponents: REGISTERED_COMPONENTS,
        apiKey: this.$env.builder.apiKey,
        model: this.model,
        includeRefs: true
      }));
    }
    const children = [];
    const fullStyles = {
      large: [],
      medium: [],
      small: []
    };
    this.content.data.blocks.forEach((block) => {
      if (block?.id?.includes('builder-pixel-')) {
        const num = parseInt(parseInt(block.id.replace('builder-pixel-', ''), '36').toString()[0]);
        if (num % 2 === 1) {
          return;
        }
      }
      const { styles, rendered } = renderBlockRecursive(block, false, {content: this.content})
      children.push(rendered);
      breakpointNames.forEach((breakpoint) => {
        if (styles[breakpoint]) {
          fullStyles[breakpoint] = fullStyles[breakpoint].concat(styles[breakpoint]);
        }
      })
    });
    let styleContent = '';
    
    fullStyles.large.forEach((styleBlock) => {
      styleContent += renderStyleBlock(styleBlock);
    });
    styleContent += '\n@media (max-width: 991px) {';
    fullStyles.medium.forEach((styleBlock) => {
      styleContent += renderStyleBlock(styleBlock);
    });
    styleContent += '}\n@media (max-width: 640px) {';
    fullStyles.small.forEach((styleBlock) => {
      styleContent += renderStyleBlock(styleBlock);
    });
    styleContent += '}';
    
    if (this.content.data.cssCode) {
      styleContent += '\n' + this.content.data.cssCode;
    }
    
    children.push(h('style', { innerHTML: styleContent }));
    return h('div', {class: ['builder-blocks', newStyles ? '--title-spacing' : null]}, children);
  }
}
</script>