import {
  advertVuexStore,
  analyticsVuexStore,
  builderVuexStore,
  eventVuexStore,
  flowLoginRegisterVuexStore,
  modalsVuexStore,
  momentumVuexStore,
  userVuexStore 
} from "#imports"
const VuexStore = {
  modules: {
      advert: {
        ...advertVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      analytics: {
        ...analyticsVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      builder: {
        ...builderVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      event: {
        ...eventVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      flowLoginRegister: {
        ...flowLoginRegisterVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      modals: {
        ...modalsVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      momentum: {
        ...momentumVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      user: {
        ...userVuexStore,
        namespaced: true,
        modules: {},
      }
    }, 
  }
export default VuexStore