// import axios from "axios";
// import { getContent, getAllContent,  } from '@builder.io/sdk-vue/vue3/lib/browser/index.js';
import { getContent, getAllContent } from '@builder_sdk'
// import { REGISTERED_COMPONENTS } from '@/init-builder.ts';

export default defineNuxtPlugin((NuxtApp) => {
  const $env = NuxtApp.$env;
  const builder = {};

  const defaultParams = {
    apiKey: $env.builder.apiKey,
    includeRefs: true,
    noTraverse: false,
  }

  const isPreview = () => NuxtApp.$router.currentRoute._rawValue.query.previewing === 'true';

  builder.getOne = async (model, params) => {
    if (isPreview()) {
      const builderParams = {
        ...defaultParams,
        model,
        ...params
      };
      if (!builderParams.options) {
        builderParams.options = {};
      }
      builderParams.options.cachebust = true;
      return getContent(builderParams);
    }
    else {
      return NuxtApp.$api.Builder.getOne(model, params);
    }
  };

  builder.getAll = async (model, params) => {
    if (isPreview()) {
      const builderParams = {
        ...defaultParams,
        model,
        ...params
      };
      if (!builderParams.options) {
        builderParams.options = {};
      }
      builderParams.options.cachebust = true;
      return getAllContent(builderParams);
    }
    else {
      return NuxtApp.$api.Builder.getAll(model, params);
    }
  };

  builder.getRef = async (ref) => {
    if (isPreview()) {
      return getContent({
        ...defaultParams,
        model: ref.model,
        query: {
          id: ref.id
        },
        options: {
          cachebust: true
        }
      });
    }
    else {
      return NuxtApp.$api.Builder.getRef(ref.id);
    }
  }

  // builder.components = () => {
  //   console.log('components', REGISTERED_COMPONENTS);
  //   return REGISTERED_COMPONENTS;
  // }; 

  const condition = (c) => {
    if (c === undefined) return true;
    if (c === true) return true;
    if (c === false) return false;
    const condition = NuxtApp.$store.getters['builder/getCondition'](c.id);
    if (condition === 'showAllTheTime') return true;

    const isLoggedIn = NuxtApp.$store.getters['user/isLoggedIn']
    if (condition === 'showLoggedIn') return isLoggedIn;
    if (condition === 'showNotLoggedIn') return !isLoggedIn;
  };

  NuxtApp.$store.$builder = builder;
  // Inject to context as $builder
  return {
    provide: {
      builder,
      condition
    }
  }
})