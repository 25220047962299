<template>
  <mks-tag tag="span" class="mks-component display-bean" :label="text" :color="color" :style="style">
  </mks-tag>
</template>
<script>
import colorsMixin from '@/mixins/colors'
export default {
  mixins: [colorsMixin],
  props: {
    text: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: 'secondary'
    },
    colorText: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      passColorProp: true
    }
  },  
  computed: {
    style() {
      let style = '';
      if (this.color) {
        style = this.getColorStyles('--color', this.color);
        style += ' ' + this.getColorStyles('--color-hover', this.color);
      }
      if (this.colorText) {
        style += this.getColorStyles('--color-inverse', this.colorText);
      } 
      return style;
    }
  }
}
</script>
<style lang="scss">
.mks-component.display-bean {
  font-family: 'SP Malton Sans', Arial, Helvetica, sans-serif;
  font-size: 0.8em;
  font-weight: normal !important;
  padding: $space-xxs $space-xs;
  border-radius: 90px;
  transform: rotate(-2deg);
}
</style>