<template>
  <span style="display: inline-block">
    <MksButton v-bind="$attrs">{{ text }}</MksButton>
  </span>
</template>

<script>
import MksButton from '@DS/components/Button.vue';
export default {
  components: {MksButton},
  props: {
    text: String
  }
}
</script>