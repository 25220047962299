<template>
  <mks-empty-state
    class="empty-state"
    :heading="heading"
    image="/assets/images/space.png"
  >
    <template v-slot:description>
      <p>
        <slot name="description">{{description}}</slot>
      </p>
    </template>
    <template v-slot:cta>
      <slot name="cta"></slot>
    </template>
  </mks-empty-state>
</template>
<script>
import MksEmptyState from "@DS/components/EmptyState.vue";
export default {
  components: {MksEmptyState},
  props: {
    type: 'events',
    title: String
  },
  computed: {
    heading() {
      if (this.lang === 'en' || this.lang === 'es' || this.type === 'events' && !this.title) {
        return this.__('L\'événement que tu recherches existe sûrement, mais dans un univers parallèle')
      }

      else if (this.title) {
        return this.title;
      }

      else {
        return this.__('Ce que tu recherches existe sûrement, mais dans un univers parallèle')
      }
    },
    description() {
      if (this.lang === 'en' || this.lang === 'es' || this.type === 'events') {
        return this.__(
          "Tu peux essayer une autre recherche ou bien créer ton propre event (si t'organises un truc franchement on vient)"
        );
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.empty-state {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
</style>