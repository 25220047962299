<template>
  <component 
    class="icon-content"
    v-if="customIcon"
    :is="component"
    :fill="fill" 
    :width="width" 
    :height="height"
    :style="`color: ${fill}`"
  >
  </component> 
  <mks-icon v-else size="20" :type="type" :color="fill" :stroke-width="iconStroke"></mks-icon>
</template>
<script>
import iconArticle from "@/assets/icons/content-article.svg"
import iconEvent from "@/assets/icons/content-event.svg"
import iconPodcast from "@/assets/icons/content-podcast.svg"
import iconVideo from "@/assets/icons/content-video.svg"

export default {
  components: {
    iconArticle,
    iconEvent,
    iconPodcast,
    iconVideo
  },
  props: {
    type: {
      type: String,
      required: true,
      default: 'article'
    },
    width: {
      type: [Number, String],
      default: '16'
    },
    height: {
      type: [Number, String],
      default: '16'
    },
    fill: {
      type: String,
      default: 'currentColor'
    }
  },
  computed: {
    sanitizedIcon() {
      return this.type.toLowerCase().replace(' ', '');
    },
    customIcon() {
      return this.sanitizedIcon === 'article' ||
        this.sanitizedIcon === 'event' || 
        this.sanitizedIcon === 'podcast' || 
        this.sanitizedIcon === 'video' ||
        this.sanitizedIcon === 'vidéo';
    },
    component() {
      if (this.sanitizedIcon === 'article') return 'icon-article';
      else if (this.sanitizedIcon === 'event') return 'icon-event';
      else if (this.sanitizedIcon === 'podcast') return 'icon-podcast';
      else if (this.sanitizedIcon === 'video' || this.sanitizedIcon === 'vidéo') return 'icon-video';
    }
  }
}
</script>
<style lang="scss" scoped>
.icon-content {

}
</style>