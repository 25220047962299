<template>
  <div>
    <!-- ajouter iframe avec la page /cover -->
    <iframe class="cover-iframe" :src="`/cover?${query}`"></iframe>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    event: Object
  },
  data() {
    return {
      query: ''
    }
  },
  created() {
    this.buildQuery();
  },
  computed: {
    ...mapGetters('user', ['profile']),
  },
  methods: {
    buildQuery() {
      const queryObject = {
        scaledown: 0.2,
        id: '640a73fae659440007f5bdc8',
        organizer: this.profile.displayname || this.profile.firstName,
        avatar: this.profile && this.profile.profileImg && this.profile.profileImg.url,
        title: this.event.title[this.lang],
        date: this.$dayjs(this.date).format(),
        tag: this.event.type && this.event.type[this.lang],
        online: !!this.event.visio,
        location: this.event.address && this.event.address[this.lang] && this.event.address[this.lang].city,
        lang: this.lang,
        color: this.event.color && this.event.color[this.lang],
        typeSlug: this.event.type && this.event.type[this.lang],
        domain: this.profile.email.match(/@makesense\.org$/) ? 'makesense' : ''
      }
      this.query = new URLSearchParams(queryObject).toString();
    }
  }
}
</script>
<style lang="scss" scoped>
.cover-iframe {
  width: 1500px;
}
</style>