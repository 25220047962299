import { copyText as clipboardCopyText } from "vue3-clipboard";

export default defineNuxtPlugin((NuxtApp) => {
  return {
    provide: {
      copyText(text, container) {
        return new Promise((resolve, reject) => {
          clipboardCopyText(text, container, (e, data) => {
            if (e) {
              reject(e);
            }
            else {
              resolve(data);
            }
          })
        });
      }
    }
  }
});
