<template>
  <mks-spacer inline align="center" spacing="xs">
    <mks-tag color="positive"
      ><mks-icon type="check"></mks-icon
    ></mks-tag>
    <mks-text weight="semibold">{{
      __("Vous avez bien été inscrit !")
    }}</mks-text>
    <mks-text v-if="showUnregisterLink" tag="p" size="small" color="light">
      <mks-link type="menu" style="color:inherit;" :to="localePath(`/e/${eventSlug}/unattend`)">Me désinscrire</mks-link>
    </mks-text>
  </mks-spacer>
</template>
<script>
export default {
  props: {
    showUnregisterLink: {
      type: Boolean,
      default: false
    },
    eventSlug: {
      type: String
    }
  }
}
</script>