<template>
  <span v-if="!event.date" class="skeleton"></span>
  <mks-spacer nowrap inline spacing="s" class="event__date" v-else>
    <mks-date-number color="tertiary" class="">{{
      number
    }}</mks-date-number>
    <mks-spacer spacing="xxxs">
      <mks-text
        tag="span"
        :size="big ? 'large' : 'normal'"
        :weight="big ? 'bold' : 'semibold'"
        class="date"
      >
        <time
          :datetime="$dayjs(event.date).format('YYYY-MM-DDThh:mm')"
          class="header__date"
        >
          <client-only>
            <slot>{{ dayTime }}</slot>
            <template #placeholder><span class="skeleton"></span></template>
          </client-only>
        </time>
      </mks-text>
      <EventMetas :event="event" color="light" :showDuration="showDuration" :showLocation="showLocation"></EventMetas>
    </mks-spacer>
  </mks-spacer>
</template>
<script>
import dateMixin from '@/mixins/date'
import EventMetas from '@/components/molecules/Event/EventMetas.vue'
export default {
  name: "Date",
  mixins: [dateMixin],
  components: {EventMetas},
  props: {
    event: {
      type: Object
    },
    big: {
      type: Boolean,
      default: false,
    },
    showDuration: {
      type: Boolean,
      default: false
    },
    showLocation: {
      type: Boolean, 
      default: false
    }
  },
  computed: {
    date() {
      this.$dayjs().format("dddd DD MMMM");
    },
    number() {
      if (!this.event.date) return;
      return this.getDateNumber(this.event.date);
    },
    dayTime() {
      if (!this.event.date) return;
      return this.relativeDate(this.event.date, true);
    },
  }
};
</script>
<style lang="scss" scoped>
/*
.event__date > .event_date:last-child {
  flex-shrink: 1;
  
}
 */
.date {
  // required for :first-letter below to work properly
  display:block;
}
.date__text {
  flex-shrink: 1;
}

.header__date {
  font-weight: $font-weight-extrabold !important;

  &::first-letter {
    text-transform: uppercase;
  }
}

.skeleton {
  display: inline-block;
  height: $space-s;
  width: 100%;
  min-width: 150px;
  max-width: 300px;
  border-radius: $border-radius-full;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.8) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    $background-color-softest;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: shine 1s infinite;
}
@keyframes shine {
  to {
    background-position: 100% 0, /* move highlight to right */ 0 0;
  }
}
</style>