
// import Vue from 'vue'

export const state = () => ({
  step: null,
  choice: null,
  source: null,
  data: {},
  event: null,
  feature: null,
  history: [],
  callback: null,
  count: 0
});

export const getters = {
  active: state => {
    return state.step !== null;
  },
  isExternalEvent: state => {
    return state.event && state.event.registerExternal;
  },
  flowSource: state => {
    return state.source;
  },
  flowData: state => {
    return state.data;
  },
  flowFeature: state => {
    return state.feature;
  },
  flowEmail: state => {
    return state.data.email
  }
};

export const mutations = {
  INCREMENT(state) {
    state.count = state.count + 1
  },
  SET_STEP(state, name) {
    state.step = name;
  },
  SET_FEATURE(state, data) {
    state.feature = data;
  },
  SET_DATA(state, data) {
    Object.keys(data).forEach(key => {
      state.data[key] = data[key];
      // Vue.set(state.data, key, data[key]);
    })
  },
  SET_SOURCE(state, data) {
    state.source = data;
  },
  SET_CALLBACK(state, data) {
    state.callback = data;
  },
  SET_EVENT(state, data) {
    state.event = data;
  },
  ADD_HISTORY(state, data) {
    state.history.push(data);
  },
  PREVIOUS(state, data) {
    const last = state.history[state.history.length -1];
    if (last === data) {
      state.history.splice(-1, 1);
      // Vue.delete(state.history, state.history.length -1);
    }
  },
  RESET(state) {
    state.step = null;
    state.callback = null;
    state.choice = null;
    state.source = null;
    state.data =  {};
    state.event = null;
    state.feature = null;
    state.history = [];
    state.count = 0;
  }
};

export const actions = {
  start(store, {source, data, feature, event, callback}) {
    store.commit('RESET');
    if (feature) store.commit('SET_FEATURE', feature);
    if (data) store.commit('SET_DATA', data);
    if (event) store.commit('SET_EVENT', event);
    if (source) store.commit('SET_SOURCE', source);
    if (callback) store.commit('SET_CALLBACK', callback);
    if (event && event.registerExternal) {
      store.dispatch('analytics/track', { event: 'Anonymous redirect - external event', data: {
          event_id: store.state.event && store.state.event._id,
        }}, {root:true});
      store.dispatch("attend", {});
    } else {
      store.dispatch('setStep', {step: 'join', props: { event: store.state.event }});
      store.commit('ADD_HISTORY', 'join');
    }
  },


  reset(store) {
    store.commit('RESET');
  },

  setStep(store, {step, props}) {
    store.commit('INCREMENT');
    store.commit('SET_STEP', step);

    if (store.state.source === 'login-page') {
      const stepMapping = {
        join: '/login/start',
        login: '/login/password',
        'choose-password': '/login/create-password',
        'register': '/login/register',
        'register-attend': '/login/register',
        'register-attend-provider': '/login/customize',
        'customize': '/login/customize'
      }
      this.$router.push(this.localePath(stepMapping[step] || step));
    }
    else {
      if (step === 'register-attend') {
        this.$router.push(this.localePath(`/e/${store.state.event.slug}/attend`) + '?create-account=true');
      }
      else if (step === 'register-attend-provider') {
        this.$router.push(this.localePath(`/e/${store.state.event.slug}/attend`));
      }
      else {
        store.dispatch('modals/open', { name: step, props }, {root: true});
      }
    }

    if (!store.rootGetters['user/isLoggedIn']) {
      store.dispatch('analytics/track', { event: 'Makesense Events - Flow', data: {
        step,
        count: store.state.count,
        source: store.state.source,
        history: store.state.history.join(', ')
      }}, {root:true});
      store.dispatch('analytics/track', { event: `Makesense Events - Flow - Step ${step}`, data: {
        count: store.state.count,
        source: store.state.source,
        history: store.state.history.join(', ')
      }}, {root:true});
    }
  },

  async choose(store, choice) {
    if (choice === 'email') {
     store.dispatch('setStep', {step: 'email'});
     store.commit('ADD_HISTORY', 'email');
    }
    else if (choice === 'google' || choice === 'facebook') {
      store.commit('INCREMENT');
      store.dispatch('analytics/track', { event: 'Makesense Events - Flow', data: {
        step: choice,
        count: store.state.count,
        source: store.state.source,
        history: store.state.history.join(', ')
      }}, {root:true});

      await this.$api.Auth.providerLogin(choice);
      await store.dispatch('user/getProfile', false, {root: true});
      const profile = store.rootGetters['user/profile'];
      if (profile.profileCompleted) {
        // todo : profileCompleted est jamais passé à true ?
        store.commit('SET_DATA', {email: profile.email});
        if (store.state.feature === 'attend-event') {
          store.dispatch('setStep', {step: 'register-attend'});
          store.commit('ADD_HISTORY', 'register-attend');
        } else {
          store.dispatch('setStep', {step: 'register'});
          store.commit('ADD_HISTORY', 'register');
        }
      }
      else {
        if (store.state.feature === 'attend-event') {
          store.dispatch('setStep', {step: 'register-attend-provider'});
        } else {
          store.dispatch('setStep', {step: 'register'});
        }
      }
    }
    else if (choice === 'login') {
      store.dispatch('setStep', {step: 'login'});
    }
    else if (choice === 'guest') {
      store.dispatch('analytics/track', { event: 'Anonymous redirect - external event', data: {
          event_id: state.event && state.event._id,
        }}, {root:true});
      store.dispatch("attend", {});
    }
  },

  async setEmail(store, email) {
    store.commit('SET_DATA', {email: email});
    store.dispatch('analytics/identify', email, {root:true});

    const account = await this.$api.User.exists(email);

    if (account.exists) {
      if (account.invited) {
        store.dispatch('modals/close', 'email', {root: true});
        store.dispatch('setStep', {step: 'choose-password'});
      }
      else if (!account.local) {
        return 'LOGIN_WITH_OAUTH';
      }
      else {
        store.dispatch('setStep', {step: 'login'});
        store.commit('ADD_HISTORY', 'login');
        store.dispatch('modals/close', 'email', {root: true});
      }
    } else {
      // account does not exist
      store.dispatch('modals/close', 'email', {root: true});
      if (store.state.feature === 'attend-event') {
        store.dispatch('setStep', {step: 'register-attend'});
        store.commit('ADD_HISTORY', 'register');
      } else {
        store.dispatch('setStep', {step: 'register'});
        store.commit('ADD_HISTORY', 'register-attend');
      }
      
    }
  },

  async setPassword(store, password) {
    const tokens = await this.$api.User.register({
      email: store.state.data.email,
      password,
      source: 'events', // todo : changer la source,
      site: this.$env.SITE
    });

    if (tokens) {
      await this.$api.Auth.setTokens(tokens.token, tokens.refresh);
      await store.dispatch('user/getProfile', false, {root: true});
      const profile = store.rootGetters['user/profile'];

      store.dispatch('loginSuccess');
      store.dispatch('modals/close', 'choose-password', {root: true});
    }
  },

  loginSuccess(store) {
    if (store.state.feature === 'attend-event') {
      store.dispatch("attend", {});
    }
    else {
      store.dispatch('modals/close', 'register', {root: true});
      console.log('SUCCESSFUL LOGIN // LOGIN USER AND LIKE EVENT');
      setTimeout(() => {
        store.state.callback();
      }, 500)
      
    }
    store.dispatch('modals/close', 'login', {root: true});
  },

  completeProviderAccount(store) {
    store.commit('SET_DATA', { newProviderAccount: true });
    store.dispatch('setStep', {step: 'customize'});
  },

  saveUserData(store, user) {
    if (user) {
      console.log('flow', user);
      store.commit('SET_DATA', { user });
      store.dispatch('setStep', {step: 'customize'});
    }
  },

  attend(store, {source, data, event}) {
    if (source) store.commit('SET_SOURCE', source);
    if (data) store.commit('SET_DATA', data);
    if (event) store.commit('SET_EVENT', event);
    store.commit('SET_FEATURE', 'attend-event');

    if (store.state.event.registerExternal) {
      store.dispatch('setStep', { step: 'redirect-external', props: { event: store.state.event } });
      store.commit('ADD_HISTORY', 'redirect-external');
    } else {
      store.dispatch('setStep', { step: 'attend', props: { event: store.state.event } });
      store.commit('ADD_HISTORY', 'attend');
    }
  },

  previous(store, current) {
    store.dispatch('modals/close', current, {root: true});
    store.commit('PREVIOUS', current);
    const previous = store.state.history[store.state.history.length - 1];
    // on envoie le props event pour éviter une erreur quand le user met un email qui n'existe qu'avec google.
    // todo: trouver pourquoi les props disparaissent -> tester d'envoyer juste props: store.state
    store.dispatch('setStep', { step: previous, props: { event: store.state.event } });

  }
};
