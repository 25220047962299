<template>
  <div class="expert-bean-container">
    <div class="expert-bean" :class="{
      '--size-large': size === 'large',
      '--size-xlarge': size === 'xlarge',
      '--size-small': size === 'small',
      '--vertical': direction === 'vertical'
    }">
      <nuxt-img
          v-if="image"
          :src="image"
          format="webp"
          sizes="xs:550px sm:800px"
          loading="lazy">
      </nuxt-img>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: String,
    direction: String,
    size: String
  }
}
</script>

<style lang="scss">
.expert-bean-container {
  max-width: 100%;
}

.expert-bean {
  overflow: hidden;
  border: 8px solid var(--color-default);
  max-width: 100%;
  
  &.--size-large {
    border-width: 9px;
    width: 515px;
    // height: 276px;
    border-radius: 263px;
    aspect-ratio: 515 / 276;
    
    &.--vertical {
      border-width: 7px;
      width: 290px;
      // height: 400px;
      border-radius: 145px;
      aspect-ratio: 290 / 400;
    }
  }
  
  &.--size-small {
    border-width: 4px;
    width: 155px;
    // height: 97px;
    border-radius: 79px;
    aspect-ratio: 155 / 97;

    &.--vertical {
      width: 97px;
      // height: 155px;
      aspect-ratio: 97 / 155;
    }
  }

  &.--size-xlarge {
    border-width: 6px;
    width: 618px;
    // height: 328px;
    border-radius: 180px;
    aspect-ratio: 618 / 328;

    &.--vertical {
      width: 328px;
      height: 618px;
    }
  }
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>