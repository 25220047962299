<template>
  <Card :to="to" class="mks-component card-link" :background="background" :textColor="textColor" :hover=true>
    <span v-if="noStyling && text" v-html="text"></span>
    <span v-else-if="noStyling">
      <slot></slot>
    </span>
    <span v-else-if="text" v-html="text" class="card-link__text"></span>
    <span v-else class="card-link__text">
      <slot></slot>
    </span>
    <mks-button class="button-arrow" :style="buttonStyle">
      <mks-icon type="arrow-right"></mks-icon>
    </mks-button>
  </Card>
  
</template>
<script>
import Card from '@/components/molecules/Cards/Card.vue';
import colorsMixin from '@/mixins/colors'
export default {
  components: {Card},
  mixins: [colorsMixin],
  props: {
    text: {
      type: String
    },
    to: {
      type: String,
      default: '#'
    },
    noStyling: {
      type: Boolean,
      default: false
    },
    background: {
      type: String,
      default: 'color-primary'
    },
    textColor: {
      type: String,
      default: 'neutral-100'
    },
    buttonBackground: {
      type: String
    }
  },
  computed: {
    buttonStyle() {
      let style = '';
      if (this.buttonBackground) style = this.getColorStyles('background', this.buttonBackground);
      else if (this.textColor) style += this.getColorStyles('background', this.textColor)
      if (this.background) style += this.getColorStyles('color', this.background);
      return style;
    }
  }
}
</script>
<style lang="scss" scoped>
.card-link{
  display: inline-flex;
  gap: 10px;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: $space-m;
  border: none !important;
}
.card-link__text {
  @include title-xxsmall;
  font-weight: 700 !important;
}
.button-arrow {
  @include button-arrow;
  border: none;
}
</style>