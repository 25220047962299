import { default as _91slug_938tGCft0aMGMeta } from "/usr/src/app/pages/[slug].vue?macro=true";
import { default as activekD6twNX6YRMeta } from "/usr/src/app/pages/account/agenda/active.vue?macro=true";
import { default as pasto0UVp2r4PvMeta } from "/usr/src/app/pages/account/agenda/past.vue?macro=true";
import { default as agendajqXdE3tKVHMeta } from "/usr/src/app/pages/account/agenda.vue?macro=true";
import { default as articlestJDmt3TXb3Meta } from "/usr/src/app/pages/account/favorites/articles.vue?macro=true";
import { default as eventswfYrPiDMWeMeta } from "/usr/src/app/pages/account/favorites/events.vue?macro=true";
import { default as topicsi5cE54PGN1Meta } from "/usr/src/app/pages/account/favorites/topics.vue?macro=true";
import { default as favoritesN8teJGxPK8Meta } from "/usr/src/app/pages/account/favorites.vue?macro=true";
import { default as help35G16pKGTuMeta } from "/usr/src/app/pages/account/help.vue?macro=true";
import { default as profileaKN25GPWXpMeta } from "/usr/src/app/pages/account/profile.vue?macro=true";
import { default as accountdWl6ticfZzMeta } from "/usr/src/app/pages/account.vue?macro=true";
import { default as actionsufVj1S7X2EMeta } from "/usr/src/app/pages/actions.vue?macro=true";
import { default as activate54eUIkxKieMeta } from "/usr/src/app/pages/activate.vue?macro=true";
import { default as adminSfHKkwn4S8Meta } from "/usr/src/app/pages/admin.vue?macro=true";
import { default as api_45errormIcI8645SKMeta } from "/usr/src/app/pages/api-error.vue?macro=true";
import { default as api_45successpXV38c6eUzMeta } from "/usr/src/app/pages/api-success.vue?macro=true";
import { default as __builder_45preview__A9st5jAHYxMeta } from "/usr/src/app/pages/builder/__builder-preview__.vue?macro=true";
import { default as __symbol_45preview__JEB6WEeIjjMeta } from "/usr/src/app/pages/builder/__symbol-preview__.vue?macro=true";
import { default as coverfplE8C8p4SMeta } from "/usr/src/app/pages/cover.vue?macro=true";
import { default as attend4NSG9A7yuCMeta } from "/usr/src/app/pages/e/[slug]/attend.vue?macro=true";
import { default as feedback2ANi8zxbMnMeta } from "/usr/src/app/pages/e/[slug]/feedback.vue?macro=true";
import { default as unattendgIrW2jm4UnMeta } from "/usr/src/app/pages/e/[slug]/unattend.vue?macro=true";
import { default as _91slug_93MelhM47TJmMeta } from "/usr/src/app/pages/e/[slug].vue?macro=true";
import { default as indexTEkxEEYOOXMeta } from "/usr/src/app/pages/events/index.vue?macro=true";
import { default as indexI0p0Ni4PmaMeta } from "/usr/src/app/pages/game/index.vue?macro=true";
import { default as play1AwkCXxUxCMeta } from "/usr/src/app/pages/game/play.vue?macro=true";
import { default as home_45chicheu2XwPXuAPdMeta } from "/usr/src/app/pages/home-chiche.vue?macro=true";
import { default as _91id_93XYJ2FwtJx5Meta } from "/usr/src/app/pages/iframe/[id].vue?macro=true";
import { default as elections2022iOey0jWzdvMeta } from "/usr/src/app/pages/iframe/elections2022.vue?macro=true";
import { default as techforgoodtour_45partnersOtsxUMMkN6Meta } from "/usr/src/app/pages/iframe/techforgoodtour-partners.vue?macro=true";
import { default as techforgoodtourr78kTElZc6Meta } from "/usr/src/app/pages/iframe/techforgoodtour.vue?macro=true";
import { default as thedaysafter6HsCzbqIcWMeta } from "/usr/src/app/pages/iframe/thedaysafter.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as create_45passwordTru2xnI5F9Meta } from "/usr/src/app/pages/login/create-password.vue?macro=true";
import { default as customizepf7KNuL3OEMeta } from "/usr/src/app/pages/login/customize.vue?macro=true";
import { default as passwordDrVQwdbIkeMeta } from "/usr/src/app/pages/login/password.vue?macro=true";
import { default as registerfTjs7HfUpfMeta } from "/usr/src/app/pages/login/register.vue?macro=true";
import { default as startOZNg4q4ZO6Meta } from "/usr/src/app/pages/login/start.vue?macro=true";
import { default as loginRGcvZC480vMeta } from "/usr/src/app/pages/login.vue?macro=true";
import { default as logoutLM6hK8aOwEMeta } from "/usr/src/app/pages/logout.vue?macro=true";
import { default as lost_45passwordt3f9rJijTbMeta } from "/usr/src/app/pages/lost-password.vue?macro=true";
import { default as _91slug_93nrwWtvDLCwMeta } from "/usr/src/app/pages/media/[category]/[slug].vue?macro=true";
import { default as indexs7LRkVcO8nMeta } from "/usr/src/app/pages/media/[category]/index.vue?macro=true";
import { default as indexnaMairafq4Meta } from "/usr/src/app/pages/media/index.vue?macro=true";
import { default as tagZtjUsGnnarMeta } from "/usr/src/app/pages/media/tag.vue?macro=true";
import { default as emailsfPCQkSKRyuMeta } from "/usr/src/app/pages/organize/create/emails.vue?macro=true";
import { default as eventUYM4AqXvDiMeta } from "/usr/src/app/pages/organize/create/event.vue?macro=true";
import { default as settingsNAMwPVGgzyMeta } from "/usr/src/app/pages/organize/create/settings.vue?macro=true";
import { default as stylewpRtfHk3SQMeta } from "/usr/src/app/pages/organize/create/style.vue?macro=true";
import { default as createbbMYB6KnOYMeta } from "/usr/src/app/pages/organize/create.vue?macro=true";
import { default as _91slug_93jVyjKvK5G1Meta } from "/usr/src/app/pages/organize/duplicate/[slug].vue?macro=true";
import { default as attendees2xZuQyWDdEMeta } from "/usr/src/app/pages/organize/event/[slug]/attendees.vue?macro=true";
import { default as dashboardmmvOFCug9EMeta } from "/usr/src/app/pages/organize/event/[slug]/dashboard.vue?macro=true";
import { default as edit9Um5Nt4PiWMeta } from "/usr/src/app/pages/organize/event/[slug]/edit.vue?macro=true";
import { default as feedbacks70tMSwfn7rMeta } from "/usr/src/app/pages/organize/event/[slug]/feedbacks.vue?macro=true";
import { default as _91slug_93zOZkdcRgeFMeta } from "/usr/src/app/pages/organize/event/[slug].vue?macro=true";
import { default as eventriyGMXqmggMeta } from "/usr/src/app/pages/organize/event.vue?macro=true";
import { default as indexdUqbdCLnhEMeta } from "/usr/src/app/pages/organize/index.vue?macro=true";
import { default as active2rFjwehfNzMeta } from "/usr/src/app/pages/organize/mine/active.vue?macro=true";
import { default as cancelledqwkAclH2RZMeta } from "/usr/src/app/pages/organize/mine/cancelled.vue?macro=true";
import { default as pastO5ahQ6ecMaMeta } from "/usr/src/app/pages/organize/mine/past.vue?macro=true";
import { default as mineOaI0mtfh7pMeta } from "/usr/src/app/pages/organize/mine.vue?macro=true";
import { default as _91slug_93goUolwLvS9Meta } from "/usr/src/app/pages/playlist/[slug].vue?macro=true";
import { default as _91slug_93Vg0KJclfY2Meta } from "/usr/src/app/pages/recos/[slug].vue?macro=true";
import { default as reset_45passwordZej1Ub4tM0Meta } from "/usr/src/app/pages/reset-password.vue?macro=true";
import { default as searchHuFsYebDR6Meta } from "/usr/src/app/pages/search.vue?macro=true";
export default [
  {
    name: _91slug_938tGCft0aMGMeta?.name ?? "slug",
    path: _91slug_938tGCft0aMGMeta?.path ?? "/:slug()",
    meta: _91slug_938tGCft0aMGMeta || {},
    alias: _91slug_938tGCft0aMGMeta?.alias || [],
    redirect: _91slug_938tGCft0aMGMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: accountdWl6ticfZzMeta?.name ?? "account",
    path: accountdWl6ticfZzMeta?.path ?? "/account",
    children: [
  {
    name: agendajqXdE3tKVHMeta?.name ?? "account-agenda",
    path: agendajqXdE3tKVHMeta?.path ?? "agenda",
    children: [
  {
    name: activekD6twNX6YRMeta?.name ?? "account-agenda-active",
    path: activekD6twNX6YRMeta?.path ?? "active",
    meta: activekD6twNX6YRMeta || {},
    alias: activekD6twNX6YRMeta?.alias || [],
    redirect: activekD6twNX6YRMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/account/agenda/active.vue").then(m => m.default || m)
  },
  {
    name: pasto0UVp2r4PvMeta?.name ?? "account-agenda-past",
    path: pasto0UVp2r4PvMeta?.path ?? "past",
    meta: pasto0UVp2r4PvMeta || {},
    alias: pasto0UVp2r4PvMeta?.alias || [],
    redirect: pasto0UVp2r4PvMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/account/agenda/past.vue").then(m => m.default || m)
  }
],
    meta: agendajqXdE3tKVHMeta || {},
    alias: agendajqXdE3tKVHMeta?.alias || [],
    redirect: agendajqXdE3tKVHMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/account/agenda.vue").then(m => m.default || m)
  },
  {
    name: favoritesN8teJGxPK8Meta?.name ?? "account-favorites",
    path: favoritesN8teJGxPK8Meta?.path ?? "favorites",
    children: [
  {
    name: articlestJDmt3TXb3Meta?.name ?? "account-favorites-articles",
    path: articlestJDmt3TXb3Meta?.path ?? "articles",
    meta: articlestJDmt3TXb3Meta || {},
    alias: articlestJDmt3TXb3Meta?.alias || [],
    redirect: articlestJDmt3TXb3Meta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/account/favorites/articles.vue").then(m => m.default || m)
  },
  {
    name: eventswfYrPiDMWeMeta?.name ?? "account-favorites-events",
    path: eventswfYrPiDMWeMeta?.path ?? "events",
    meta: eventswfYrPiDMWeMeta || {},
    alias: eventswfYrPiDMWeMeta?.alias || [],
    redirect: eventswfYrPiDMWeMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/account/favorites/events.vue").then(m => m.default || m)
  },
  {
    name: topicsi5cE54PGN1Meta?.name ?? "account-favorites-topics",
    path: topicsi5cE54PGN1Meta?.path ?? "topics",
    meta: topicsi5cE54PGN1Meta || {},
    alias: topicsi5cE54PGN1Meta?.alias || [],
    redirect: topicsi5cE54PGN1Meta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/account/favorites/topics.vue").then(m => m.default || m)
  }
],
    meta: favoritesN8teJGxPK8Meta || {},
    alias: favoritesN8teJGxPK8Meta?.alias || [],
    redirect: favoritesN8teJGxPK8Meta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/account/favorites.vue").then(m => m.default || m)
  },
  {
    name: help35G16pKGTuMeta?.name ?? "account-help",
    path: help35G16pKGTuMeta?.path ?? "help",
    meta: help35G16pKGTuMeta || {},
    alias: help35G16pKGTuMeta?.alias || [],
    redirect: help35G16pKGTuMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/account/help.vue").then(m => m.default || m)
  },
  {
    name: profileaKN25GPWXpMeta?.name ?? "account-profile",
    path: profileaKN25GPWXpMeta?.path ?? "profile",
    meta: profileaKN25GPWXpMeta || {},
    alias: profileaKN25GPWXpMeta?.alias || [],
    redirect: profileaKN25GPWXpMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/account/profile.vue").then(m => m.default || m)
  }
],
    meta: accountdWl6ticfZzMeta || {},
    alias: accountdWl6ticfZzMeta?.alias || [],
    redirect: accountdWl6ticfZzMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/account.vue").then(m => m.default || m)
  },
  {
    name: actionsufVj1S7X2EMeta?.name ?? "actions",
    path: actionsufVj1S7X2EMeta?.path ?? "/actions",
    meta: actionsufVj1S7X2EMeta || {},
    alias: actionsufVj1S7X2EMeta?.alias || [],
    redirect: actionsufVj1S7X2EMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/actions.vue").then(m => m.default || m)
  },
  {
    name: activate54eUIkxKieMeta?.name ?? "activate",
    path: activate54eUIkxKieMeta?.path ?? "/activate",
    meta: activate54eUIkxKieMeta || {},
    alias: activate54eUIkxKieMeta?.alias || [],
    redirect: activate54eUIkxKieMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/activate.vue").then(m => m.default || m)
  },
  {
    name: adminSfHKkwn4S8Meta?.name ?? "admin",
    path: adminSfHKkwn4S8Meta?.path ?? "/admin",
    meta: adminSfHKkwn4S8Meta || {},
    alias: adminSfHKkwn4S8Meta?.alias || [],
    redirect: adminSfHKkwn4S8Meta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/admin.vue").then(m => m.default || m)
  },
  {
    name: api_45errormIcI8645SKMeta?.name ?? "api-error",
    path: api_45errormIcI8645SKMeta?.path ?? "/api-error",
    meta: api_45errormIcI8645SKMeta || {},
    alias: api_45errormIcI8645SKMeta?.alias || [],
    redirect: api_45errormIcI8645SKMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/api-error.vue").then(m => m.default || m)
  },
  {
    name: api_45successpXV38c6eUzMeta?.name ?? "api-success",
    path: api_45successpXV38c6eUzMeta?.path ?? "/api-success",
    meta: api_45successpXV38c6eUzMeta || {},
    alias: api_45successpXV38c6eUzMeta?.alias || [],
    redirect: api_45successpXV38c6eUzMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/api-success.vue").then(m => m.default || m)
  },
  {
    name: __builder_45preview__A9st5jAHYxMeta?.name ?? "builder-__builder-preview__",
    path: __builder_45preview__A9st5jAHYxMeta?.path ?? "/builder/__builder-preview__",
    meta: __builder_45preview__A9st5jAHYxMeta || {},
    alias: __builder_45preview__A9st5jAHYxMeta?.alias || [],
    redirect: __builder_45preview__A9st5jAHYxMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/builder/__builder-preview__.vue").then(m => m.default || m)
  },
  {
    name: __symbol_45preview__JEB6WEeIjjMeta?.name ?? "builder-__symbol-preview__",
    path: __symbol_45preview__JEB6WEeIjjMeta?.path ?? "/builder/__symbol-preview__",
    meta: __symbol_45preview__JEB6WEeIjjMeta || {},
    alias: __symbol_45preview__JEB6WEeIjjMeta?.alias || [],
    redirect: __symbol_45preview__JEB6WEeIjjMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/builder/__symbol-preview__.vue").then(m => m.default || m)
  },
  {
    name: coverfplE8C8p4SMeta?.name ?? "cover",
    path: coverfplE8C8p4SMeta?.path ?? "/cover",
    meta: coverfplE8C8p4SMeta || {},
    alias: coverfplE8C8p4SMeta?.alias || [],
    redirect: coverfplE8C8p4SMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/cover.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MelhM47TJmMeta?.name ?? "events-e-slug",
    path: _91slug_93MelhM47TJmMeta?.path ?? "/events/e/:slug()",
    children: [
  {
    name: attend4NSG9A7yuCMeta?.name ?? "events-e-slug-attend",
    path: attend4NSG9A7yuCMeta?.path ?? "attend",
    meta: attend4NSG9A7yuCMeta || {},
    alias: attend4NSG9A7yuCMeta?.alias || [],
    redirect: attend4NSG9A7yuCMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/e/[slug]/attend.vue").then(m => m.default || m)
  },
  {
    name: feedback2ANi8zxbMnMeta?.name ?? "events-e-slug-feedback",
    path: feedback2ANi8zxbMnMeta?.path ?? "feedback",
    meta: feedback2ANi8zxbMnMeta || {},
    alias: feedback2ANi8zxbMnMeta?.alias || [],
    redirect: feedback2ANi8zxbMnMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/e/[slug]/feedback.vue").then(m => m.default || m)
  },
  {
    name: unattendgIrW2jm4UnMeta?.name ?? "events-e-slug-unattend",
    path: unattendgIrW2jm4UnMeta?.path ?? "unattend",
    meta: unattendgIrW2jm4UnMeta || {},
    alias: unattendgIrW2jm4UnMeta?.alias || [],
    redirect: unattendgIrW2jm4UnMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/e/[slug]/unattend.vue").then(m => m.default || m)
  }
],
    meta: _91slug_93MelhM47TJmMeta || {},
    alias: _91slug_93MelhM47TJmMeta?.alias || [],
    redirect: _91slug_93MelhM47TJmMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/e/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexTEkxEEYOOXMeta?.name ?? "events",
    path: indexTEkxEEYOOXMeta?.path ?? "/events",
    meta: indexTEkxEEYOOXMeta || {},
    alias: indexTEkxEEYOOXMeta?.alias || [],
    redirect: indexTEkxEEYOOXMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexI0p0Ni4PmaMeta?.name ?? "game",
    path: indexI0p0Ni4PmaMeta?.path ?? "/game",
    meta: indexI0p0Ni4PmaMeta || {},
    alias: indexI0p0Ni4PmaMeta?.alias || [],
    redirect: indexI0p0Ni4PmaMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/game/index.vue").then(m => m.default || m)
  },
  {
    name: play1AwkCXxUxCMeta?.name ?? "game-play",
    path: play1AwkCXxUxCMeta?.path ?? "/game/play",
    meta: play1AwkCXxUxCMeta || {},
    alias: play1AwkCXxUxCMeta?.alias || [],
    redirect: play1AwkCXxUxCMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/game/play.vue").then(m => m.default || m)
  },
  {
    name: home_45chicheu2XwPXuAPdMeta?.name ?? "index",
    path: home_45chicheu2XwPXuAPdMeta?.path ?? "/",
    meta: home_45chicheu2XwPXuAPdMeta || {},
    alias: home_45chicheu2XwPXuAPdMeta?.alias || [],
    redirect: home_45chicheu2XwPXuAPdMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/home-chiche.vue").then(m => m.default || m)
  },
  {
    name: _91id_93XYJ2FwtJx5Meta?.name ?? "iframe-id",
    path: _91id_93XYJ2FwtJx5Meta?.path ?? "/iframe/:id()",
    meta: _91id_93XYJ2FwtJx5Meta || {},
    alias: _91id_93XYJ2FwtJx5Meta?.alias || [],
    redirect: _91id_93XYJ2FwtJx5Meta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/iframe/[id].vue").then(m => m.default || m)
  },
  {
    name: elections2022iOey0jWzdvMeta?.name ?? "iframe-elections2022",
    path: elections2022iOey0jWzdvMeta?.path ?? "/iframe/elections2022",
    meta: elections2022iOey0jWzdvMeta || {},
    alias: elections2022iOey0jWzdvMeta?.alias || [],
    redirect: elections2022iOey0jWzdvMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/iframe/elections2022.vue").then(m => m.default || m)
  },
  {
    name: techforgoodtour_45partnersOtsxUMMkN6Meta?.name ?? "iframe-techforgoodtour-partners",
    path: techforgoodtour_45partnersOtsxUMMkN6Meta?.path ?? "/iframe/techforgoodtour-partners",
    meta: techforgoodtour_45partnersOtsxUMMkN6Meta || {},
    alias: techforgoodtour_45partnersOtsxUMMkN6Meta?.alias || [],
    redirect: techforgoodtour_45partnersOtsxUMMkN6Meta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/iframe/techforgoodtour-partners.vue").then(m => m.default || m)
  },
  {
    name: techforgoodtourr78kTElZc6Meta?.name ?? "iframe-techforgoodtour",
    path: techforgoodtourr78kTElZc6Meta?.path ?? "/iframe/techforgoodtour",
    meta: techforgoodtourr78kTElZc6Meta || {},
    alias: techforgoodtourr78kTElZc6Meta?.alias || [],
    redirect: techforgoodtourr78kTElZc6Meta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/iframe/techforgoodtour.vue").then(m => m.default || m)
  },
  {
    name: thedaysafter6HsCzbqIcWMeta?.name ?? "iframe-thedaysafter",
    path: thedaysafter6HsCzbqIcWMeta?.path ?? "/iframe/thedaysafter",
    meta: thedaysafter6HsCzbqIcWMeta || {},
    alias: thedaysafter6HsCzbqIcWMeta?.alias || [],
    redirect: thedaysafter6HsCzbqIcWMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/iframe/thedaysafter.vue").then(m => m.default || m)
  },
  {
    name: indexgw2ikJsyhcMeta?.name ?? "events",
    path: indexgw2ikJsyhcMeta?.path ?? "/events",
    meta: indexgw2ikJsyhcMeta || {},
    alias: indexgw2ikJsyhcMeta?.alias || [],
    redirect: indexgw2ikJsyhcMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginRGcvZC480vMeta?.name ?? "login",
    path: loginRGcvZC480vMeta?.path ?? "/login",
    children: [
  {
    name: create_45passwordTru2xnI5F9Meta?.name ?? "login-create-password",
    path: create_45passwordTru2xnI5F9Meta?.path ?? "create-password",
    meta: create_45passwordTru2xnI5F9Meta || {},
    alias: create_45passwordTru2xnI5F9Meta?.alias || [],
    redirect: create_45passwordTru2xnI5F9Meta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/login/create-password.vue").then(m => m.default || m)
  },
  {
    name: customizepf7KNuL3OEMeta?.name ?? "login-customize",
    path: customizepf7KNuL3OEMeta?.path ?? "customize",
    meta: customizepf7KNuL3OEMeta || {},
    alias: customizepf7KNuL3OEMeta?.alias || [],
    redirect: customizepf7KNuL3OEMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/login/customize.vue").then(m => m.default || m)
  },
  {
    name: passwordDrVQwdbIkeMeta?.name ?? "login-password",
    path: passwordDrVQwdbIkeMeta?.path ?? "password",
    meta: passwordDrVQwdbIkeMeta || {},
    alias: passwordDrVQwdbIkeMeta?.alias || [],
    redirect: passwordDrVQwdbIkeMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/login/password.vue").then(m => m.default || m)
  },
  {
    name: registerfTjs7HfUpfMeta?.name ?? "login-register",
    path: registerfTjs7HfUpfMeta?.path ?? "register",
    meta: registerfTjs7HfUpfMeta || {},
    alias: registerfTjs7HfUpfMeta?.alias || [],
    redirect: registerfTjs7HfUpfMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/login/register.vue").then(m => m.default || m)
  },
  {
    name: startOZNg4q4ZO6Meta?.name ?? "login-start",
    path: startOZNg4q4ZO6Meta?.path ?? "start",
    meta: startOZNg4q4ZO6Meta || {},
    alias: startOZNg4q4ZO6Meta?.alias || [],
    redirect: startOZNg4q4ZO6Meta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/login/start.vue").then(m => m.default || m)
  }
],
    meta: loginRGcvZC480vMeta || {},
    alias: loginRGcvZC480vMeta?.alias || [],
    redirect: loginRGcvZC480vMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logoutLM6hK8aOwEMeta?.name ?? "logout",
    path: logoutLM6hK8aOwEMeta?.path ?? "/logout",
    meta: logoutLM6hK8aOwEMeta || {},
    alias: logoutLM6hK8aOwEMeta?.alias || [],
    redirect: logoutLM6hK8aOwEMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: lost_45passwordt3f9rJijTbMeta?.name ?? "lost-password",
    path: lost_45passwordt3f9rJijTbMeta?.path ?? "/lost-password",
    meta: lost_45passwordt3f9rJijTbMeta || {},
    alias: lost_45passwordt3f9rJijTbMeta?.alias || [],
    redirect: lost_45passwordt3f9rJijTbMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/lost-password.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nrwWtvDLCwMeta?.name ?? "media-category-slug",
    path: _91slug_93nrwWtvDLCwMeta?.path ?? "/media/:category()/:slug()",
    meta: _91slug_93nrwWtvDLCwMeta || {},
    alias: _91slug_93nrwWtvDLCwMeta?.alias || [],
    redirect: _91slug_93nrwWtvDLCwMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/media/[category]/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexs7LRkVcO8nMeta?.name ?? "media-category",
    path: indexs7LRkVcO8nMeta?.path ?? "/media/:category()",
    meta: indexs7LRkVcO8nMeta || {},
    alias: indexs7LRkVcO8nMeta?.alias || [],
    redirect: indexs7LRkVcO8nMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/media/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexnaMairafq4Meta?.name ?? "media",
    path: indexnaMairafq4Meta?.path ?? "/media",
    meta: indexnaMairafq4Meta || {},
    alias: indexnaMairafq4Meta?.alias || [],
    redirect: indexnaMairafq4Meta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/media/index.vue").then(m => m.default || m)
  },
  {
    name: tagZtjUsGnnarMeta?.name ?? "media-tag",
    path: tagZtjUsGnnarMeta?.path ?? "/media/tag",
    meta: tagZtjUsGnnarMeta || {},
    alias: tagZtjUsGnnarMeta?.alias || [],
    redirect: tagZtjUsGnnarMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/media/tag.vue").then(m => m.default || m)
  },
  {
    name: createbbMYB6KnOYMeta?.name ?? "organize-create",
    path: createbbMYB6KnOYMeta?.path ?? "/organize/create",
    children: [
  {
    name: emailsfPCQkSKRyuMeta?.name ?? "organize-create-emails",
    path: emailsfPCQkSKRyuMeta?.path ?? "emails",
    meta: emailsfPCQkSKRyuMeta || {},
    alias: emailsfPCQkSKRyuMeta?.alias || [],
    redirect: emailsfPCQkSKRyuMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/organize/create/emails.vue").then(m => m.default || m)
  },
  {
    name: eventUYM4AqXvDiMeta?.name ?? "organize-create-event",
    path: eventUYM4AqXvDiMeta?.path ?? "event",
    meta: eventUYM4AqXvDiMeta || {},
    alias: eventUYM4AqXvDiMeta?.alias || [],
    redirect: eventUYM4AqXvDiMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/organize/create/event.vue").then(m => m.default || m)
  },
  {
    name: settingsNAMwPVGgzyMeta?.name ?? "organize-create-settings",
    path: settingsNAMwPVGgzyMeta?.path ?? "settings",
    meta: settingsNAMwPVGgzyMeta || {},
    alias: settingsNAMwPVGgzyMeta?.alias || [],
    redirect: settingsNAMwPVGgzyMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/organize/create/settings.vue").then(m => m.default || m)
  },
  {
    name: stylewpRtfHk3SQMeta?.name ?? "organize-create-style",
    path: stylewpRtfHk3SQMeta?.path ?? "style",
    meta: stylewpRtfHk3SQMeta || {},
    alias: stylewpRtfHk3SQMeta?.alias || [],
    redirect: stylewpRtfHk3SQMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/organize/create/style.vue").then(m => m.default || m)
  }
],
    meta: createbbMYB6KnOYMeta || {},
    alias: createbbMYB6KnOYMeta?.alias || [],
    redirect: createbbMYB6KnOYMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/organize/create.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jVyjKvK5G1Meta?.name ?? "organize-duplicate-slug",
    path: _91slug_93jVyjKvK5G1Meta?.path ?? "/organize/duplicate/:slug()",
    meta: _91slug_93jVyjKvK5G1Meta || {},
    alias: _91slug_93jVyjKvK5G1Meta?.alias || [],
    redirect: _91slug_93jVyjKvK5G1Meta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/organize/duplicate/[slug].vue").then(m => m.default || m)
  },
  {
    name: eventriyGMXqmggMeta?.name ?? "organize-event",
    path: eventriyGMXqmggMeta?.path ?? "/organize/event",
    children: [
  {
    name: _91slug_93zOZkdcRgeFMeta?.name ?? "organize-event-slug",
    path: _91slug_93zOZkdcRgeFMeta?.path ?? ":slug()",
    children: [
  {
    name: attendees2xZuQyWDdEMeta?.name ?? "organize-event-slug-attendees",
    path: attendees2xZuQyWDdEMeta?.path ?? "attendees",
    meta: attendees2xZuQyWDdEMeta || {},
    alias: attendees2xZuQyWDdEMeta?.alias || [],
    redirect: attendees2xZuQyWDdEMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/organize/event/[slug]/attendees.vue").then(m => m.default || m)
  },
  {
    name: dashboardmmvOFCug9EMeta?.name ?? "organize-event-slug-dashboard",
    path: dashboardmmvOFCug9EMeta?.path ?? "dashboard",
    meta: dashboardmmvOFCug9EMeta || {},
    alias: dashboardmmvOFCug9EMeta?.alias || [],
    redirect: dashboardmmvOFCug9EMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/organize/event/[slug]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: edit9Um5Nt4PiWMeta?.name ?? "organize-event-slug-edit",
    path: edit9Um5Nt4PiWMeta?.path ?? "edit",
    meta: edit9Um5Nt4PiWMeta || {},
    alias: edit9Um5Nt4PiWMeta?.alias || [],
    redirect: edit9Um5Nt4PiWMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/organize/event/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: feedbacks70tMSwfn7rMeta?.name ?? "organize-event-slug-feedbacks",
    path: feedbacks70tMSwfn7rMeta?.path ?? "feedbacks",
    meta: feedbacks70tMSwfn7rMeta || {},
    alias: feedbacks70tMSwfn7rMeta?.alias || [],
    redirect: feedbacks70tMSwfn7rMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/organize/event/[slug]/feedbacks.vue").then(m => m.default || m)
  }
],
    meta: _91slug_93zOZkdcRgeFMeta || {},
    alias: _91slug_93zOZkdcRgeFMeta?.alias || [],
    redirect: _91slug_93zOZkdcRgeFMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/organize/event/[slug].vue").then(m => m.default || m)
  }
],
    meta: eventriyGMXqmggMeta || {},
    alias: eventriyGMXqmggMeta?.alias || [],
    redirect: eventriyGMXqmggMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/organize/event.vue").then(m => m.default || m)
  },
  {
    name: indexdUqbdCLnhEMeta?.name ?? "organize",
    path: indexdUqbdCLnhEMeta?.path ?? "/organize",
    meta: indexdUqbdCLnhEMeta || {},
    alias: indexdUqbdCLnhEMeta?.alias || [],
    redirect: indexdUqbdCLnhEMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/organize/index.vue").then(m => m.default || m)
  },
  {
    name: mineOaI0mtfh7pMeta?.name ?? "organize-mine",
    path: mineOaI0mtfh7pMeta?.path ?? "/organize/mine",
    children: [
  {
    name: active2rFjwehfNzMeta?.name ?? "organize-mine-active",
    path: active2rFjwehfNzMeta?.path ?? "active",
    meta: active2rFjwehfNzMeta || {},
    alias: active2rFjwehfNzMeta?.alias || [],
    redirect: active2rFjwehfNzMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/organize/mine/active.vue").then(m => m.default || m)
  },
  {
    name: cancelledqwkAclH2RZMeta?.name ?? "organize-mine-cancelled",
    path: cancelledqwkAclH2RZMeta?.path ?? "cancelled",
    meta: cancelledqwkAclH2RZMeta || {},
    alias: cancelledqwkAclH2RZMeta?.alias || [],
    redirect: cancelledqwkAclH2RZMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/organize/mine/cancelled.vue").then(m => m.default || m)
  },
  {
    name: pastO5ahQ6ecMaMeta?.name ?? "organize-mine-past",
    path: pastO5ahQ6ecMaMeta?.path ?? "past",
    meta: pastO5ahQ6ecMaMeta || {},
    alias: pastO5ahQ6ecMaMeta?.alias || [],
    redirect: pastO5ahQ6ecMaMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/organize/mine/past.vue").then(m => m.default || m)
  }
],
    meta: mineOaI0mtfh7pMeta || {},
    alias: mineOaI0mtfh7pMeta?.alias || [],
    redirect: mineOaI0mtfh7pMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/organize/mine.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93goUolwLvS9Meta?.name ?? "playlist-slug",
    path: _91slug_93goUolwLvS9Meta?.path ?? "/playlist/:slug()",
    meta: _91slug_93goUolwLvS9Meta || {},
    alias: _91slug_93goUolwLvS9Meta?.alias || [],
    redirect: _91slug_93goUolwLvS9Meta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/playlist/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Vg0KJclfY2Meta?.name ?? "recos-slug",
    path: _91slug_93Vg0KJclfY2Meta?.path ?? "/recos/:slug()",
    meta: _91slug_93Vg0KJclfY2Meta || {},
    alias: _91slug_93Vg0KJclfY2Meta?.alias || [],
    redirect: _91slug_93Vg0KJclfY2Meta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/recos/[slug].vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordZej1Ub4tM0Meta?.name ?? "reset-password",
    path: reset_45passwordZej1Ub4tM0Meta?.path ?? "/reset-password",
    meta: reset_45passwordZej1Ub4tM0Meta || {},
    alias: reset_45passwordZej1Ub4tM0Meta?.alias || [],
    redirect: reset_45passwordZej1Ub4tM0Meta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: searchHuFsYebDR6Meta?.name ?? "search",
    path: searchHuFsYebDR6Meta?.path ?? "/search",
    meta: searchHuFsYebDR6Meta || {},
    alias: searchHuFsYebDR6Meta?.alias || [],
    redirect: searchHuFsYebDR6Meta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: indexTEkxEEYOOXMeta?.name ?? "events",
    path: indexTEkxEEYOOXMeta?.path ?? "/events",
    meta: indexTEkxEEYOOXMeta || {},
    alias: indexTEkxEEYOOXMeta?.alias || [],
    redirect: indexTEkxEEYOOXMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexTEkxEEYOOXMeta?.name ?? "home-events",
    path: indexTEkxEEYOOXMeta?.path ?? "/events",
    meta: indexTEkxEEYOOXMeta || {},
    alias: indexTEkxEEYOOXMeta?.alias || [],
    redirect: indexTEkxEEYOOXMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: home_45chicheu2XwPXuAPdMeta?.name ?? "home-chiche",
    path: home_45chicheu2XwPXuAPdMeta?.path ?? "/",
    meta: home_45chicheu2XwPXuAPdMeta || {},
    alias: home_45chicheu2XwPXuAPdMeta?.alias || [],
    redirect: home_45chicheu2XwPXuAPdMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/home-chiche.vue").then(m => m.default || m)
  },
  {
    name: indexgw2ikJsyhcMeta?.name ?? "events-city",
    path: indexgw2ikJsyhcMeta?.path ?? "/events/city/:city",
    meta: indexgw2ikJsyhcMeta || {},
    alias: indexgw2ikJsyhcMeta?.alias || [],
    redirect: indexgw2ikJsyhcMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexgw2ikJsyhcMeta?.name ?? "events-city-topic",
    path: indexgw2ikJsyhcMeta?.path ?? "/events/city/:city/topic/:topic",
    meta: indexgw2ikJsyhcMeta || {},
    alias: indexgw2ikJsyhcMeta?.alias || [],
    redirect: indexgw2ikJsyhcMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexgw2ikJsyhcMeta?.name ?? "events-topic",
    path: indexgw2ikJsyhcMeta?.path ?? "/events/topic/:topic",
    meta: indexgw2ikJsyhcMeta || {},
    alias: indexgw2ikJsyhcMeta?.alias || [],
    redirect: indexgw2ikJsyhcMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexgw2ikJsyhcMeta?.name ?? "events-type",
    path: indexgw2ikJsyhcMeta?.path ?? "/events/type/:type",
    meta: indexgw2ikJsyhcMeta || {},
    alias: indexgw2ikJsyhcMeta?.alias || [],
    redirect: indexgw2ikJsyhcMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  }
]