<template>
  <div>
    <Teaser
      v-if="content"
      :variant="variant"
      :title="content.title[content.lang]"
      :text="content.description ? content.description[content.lang] : undefined"
      :image="cover"
      :to="localePath(`/e/${content.slug}`)"
      :tagIcon="variant === 'square' || variant === 'spotlight' ? 'event' : undefined"
      :tagLabel="tagLabel"
      :event="content"
      :show-like-on-cover="showLikeOnCover"
      :show-description="showDescription"
      :target="target"
      :source="source"
      :already-registered="alreadyRegistered"
      tagColor="roman"
      @click="doTracking()"
      @liked="$emit('liked', content._id)"
      @removed="$emit('removed', content._id)"
    />
    <mks-loading type="standalone" v-else></mks-loading>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Teaser from '../../molecules/Teaser.vue'
import {useAsyncData, useNuxtApp} from "nuxt/app";
export default {
  components: {Teaser},
  props: {
    slug: {
      type: String,
    },
    event: {
      type: Object
    },
    variant: {
      type: String,
      default: 'text' // "text", "spolight",
    },
    image: {
      type: String
    },
    showLikeOnCover: {
      type: Boolean,
      default: true
    },
    showDescription: {
      type: Boolean,
      default: true
    },
    target: {
      type: String
    },
    source: {
      type: String
    },
    useCoverDisplay: {
      type: Boolean,
      default: false
    },
    track: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      content: null
    }
  },
  async setup(props) {
    const NuxtApp = useNuxtApp();
    let content = null;
    if (props.event) {
      content = props.event;
    }
    else if (props.slug) {
      try {
        const eventsResponse = await useAsyncData(`card-${props.slug}`, () => NuxtApp.$api.Events.bySlug(props.slug));
        content = eventsResponse.data;
      } catch (e) {
        console.log(e);
      }
    }
    return {
      content
    };
  },
  computed: {
    ...mapGetters("user", ["userLoading", "isLoggedIn", "profile"]),
    cover() {
      if (this.image) return this.image;
      else if (this.useCoverDisplay && this.content.coverDisplay) return this.content.coverDisplay.url;
      else if (this.content.cover) return this.content.cover[this.content.lang].url;
      else return 'https://images.unsplash.com/photo-1661956600655-e772b2b97db4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1035&q=80';
    },
    tagLabel() {
      if (!this.content.type) return;
      // todo ici surement mieux d'avoir un prop showType // showSubType plutôt que de conditionner sur le layout.
      if (this.content.type && this.content.type.slug && this.variant !== 'square') {
        return this.content.type.name.fr;
      }
      else return 'Événement'
    },
    alreadyRegistered() {
      // if (this.preview) return;
      if (this.content.registerExternal) return false;
      if (!this.profile) return false;
      if (!this.content.attendees || this.content.attendees.length < 1) return false;
      return this.content.attendees.filter(a => {
        if (!a.user) return false;
        return a.user === this.profile._id || a.user._id === this.profile._id
      }).length > 0 || this.$store.state.event.attended.indexOf(this.content._id) > -1;
    },
  },
  watch: {
    slug() {
      this.getContent();
    }
  },
  methods: {
    async getContent() {
      this.content = await this.$api.Events.bySlug(this.slug);
    },
    doTracking() {
      if (this.track) {
        this.$store.dispatch("analytics/track", {
          event: "Makesense Chiche - " + this.track
        });
      }
    }
  }
}
</script>
<style lang="scss">
.event-teaser {
  max-height: 100%;
  // max-width: 400px;
}
</style>