export const state = () => ({
  name: null,
  logo: null,
  join: null,
  content: null
});

export const getters = {
  get: state => {
    return state.content;
  },
  getLogo: state => {
    return state.logo;
  },
  getJoin: state => {
    return state.join;
  }
};

export const mutations = {
  SET_LOGO(state, payload) {
    state.logo = payload;
  },
  SET_JOIN(state, payload) {
    state.join = payload;
  },
  SET_CONTENT(state, payload) {
    state.content = payload;
  }
};

export const actions = {
  set(state, momentum) {
    state.commit('SET_CONTENT', momentum)
    if (momentum.picto) {
        state.commit("SET_LOGO", momentum.picto.url);
    } else state.commit("SET_LOGO", null);
    if (momentum.links && momentum.links.join) {
        state.commit('SET_JOIN', momentum.links.join);
    } else state.commit('SET_JOIN', null);
  },
  reset(state) {
    state.commit('SET_CONTENT', null);
    state.commit('SET_LOGO', null);
    state.commit('SET_JOIN', null);
  }
};
