<template>
  <mks-text v-if="showIfEventIsPast && pastEvent && !currentlyHappening" color="light" size="small" weight="semibold">{{
    __("Oh dommage cet événement est déjà passé !")
  }}</mks-text>
  <mks-text
    v-else-if="event.status == 'cancelled'"
    tag="p"
    weight="semibold"
    color="negative"
    >{{ __("Annulé") }}</mks-text
  >
  <EventCardRegistrationConfirmation 
    :showUnregisterLink="true" 
    v-else-if="!event.registerExternal && alreadyRegistered" 
    :eventSlug="event.slug"
  />
  <mks-text
    v-else-if="availableTickets == 0 && event.maxAttendees > 0 && !event.registerExternal"
    tag="p"
    weight="semibold"
    color="negative"
    >{{ __("C'est complet !") }}</mks-text
  >
  <div v-else-if="!pastEvent">
    <client-only>
      <tippy
        v-if="showParticipateButton && isLoggedIn && !profile.active"
        :to="`trigger-attend-${event._id}`"
        interactive
      >
        <mks-text size="small">
          <span class="tippy__header">{{
            __("Confirme ton email pour pouvoir t'inscrire à l'évènement.")
          }}</span>
          <mks-link
            v-if="sniperLink"
            type="menu"
            class="participate-button__banner-link"
            :to="sniperLink"
            ><mks-text weight="semibold" size="small"
              >{{ __("Ouvrir") }} {{ mailProviderLabel }}</mks-text
            >
            <mks-icon size="14" type="external-link"></mks-icon
          ></mks-link>
          <mks-button
            class="banner__secondary-link"
            size="small"
            variant="bare"
            @click="resendActivationEmail"
            >{{ __("Renvoyer l'email") }}</mks-button
          >
        </mks-text>
      </tippy>
    </client-only>
    <mks-spacer inline spacing="s" align="center" class="participate-button__cta">
      <mks-button
        v-if="showParticipateButton"
        :name="`trigger-attend-${event._id}`"
        tag="button"
        input-type="submit"
        size="small"
        type="secondary"
        :loading="loading"
        :disabled="loading"
        @click="register"
        >{{ __("Participer") }}</mks-button
      >
      <Like :id="event._id"></Like>
      <div v-if="!event.registerExternal">
        <mks-text
          v-if="
            event.attendees &&
            event.attendees.length &&
            event.attendees.length > 10
          "
          weight="bold"
          tag="p"
          size="small"
          >{{
            _x("${count} participant(s)", "Texte nombre d'inscrits", {
              count: event.attendees && event.attendees.length,
            })
          }}</mks-text
        >
        <slot></slot>
      </div>
    </mks-spacer>
  </div>
</template>
<script>
import EventCardRegistrationConfirmation from './RegistrationConfirmation.vue';
import { mapGetters } from 'vuex';
import sniperLink from '@/mixins/sniperLink';

export default {
  mixins: [sniperLink],
  components: {
    EventCardRegistrationConfirmation
  },
  props: {
    event: Object,
    showParticipateButton: Boolean,
    showIfEventIsPast: Boolean,
    source: String
  },
  computed: {
    ...mapGetters("user", ["userLoading", "isLoggedIn", "profile"]),
    sniperLink() {
      if (this.profile) return this.getSniperLink(this.profile.email);
    },
    loading() {
      return this.$store.state.event.loadingAttend === this.event._id;
    },
    availableTickets() {
      // if (this.preview) return;
      return (
        parseInt(this.event.maxAttendees) -
        parseInt((this.event.attendees && this.event.attendees.length) || 0)
      );
    },
    alreadyRegistered() {
      // if (this.preview) return;
      if (this.event.registerExternal) return false;
      if (!this.profile) return false;
      if (!this.event.attendees || this.event.attendees.length < 1) return false;
      return this.event.attendees.filter(a => {
        if (!a.user) return false;
        return a.user === this.profile._id || a.user._id === this.profile._id
      }).length > 0 || this.$store.state.event.attended.indexOf(this.event._id) > -1;
    },
    pastEvent() {
      // if (this.preview) return;
      return this.$dayjs(this.event.endDate).isBefore(this.$dayjs());
    },
    currentlyHappening() {
      return this.$dayjs(this.event.date).isBefore(this.$dayjs()) && this.$dayjs(this.event.endDate).isAfter(this.$dayjs());
    }
  },
  methods: {
    resendActivationEmail() {
      this.$api.User.resendActivationEmail(this.profile.email)
        .then(res => {
          this.$toasted.show(
              this.__("L'email d'activation a été renvoyé"),
              {
                type: "success",
                position: "bottom-center",
                duration: 3000,
              }
            );
        });
    },
    register() {
      if (this.isLoggedIn) {
        if (!this.profile.active) return;
        // todo : determine source from route
        this.$store.dispatch("flowLoginRegister/attend", {
            source: this.source,
            event: this.event
          });
      } else {
        // console.log('event:', this.event);
        this.$store.dispatch("flowLoginRegister/start", {
          source: this.source,  // single-event ou home ou agenda etc
          feature: 'attend-event',
          event: this.event
        });
      }
    },
  },
};
</script>
<style lang="scss">
.participate-button__banner-link {
  margin-left: $space-s;
  margin-right: $space-xs;
}
.participate-button__cta {
  display: flex;
  align-items: center;
  margin-top: auto !important;

  .spacer__item--grow {
    flex-grow: 1 !important;
  }
}
</style>