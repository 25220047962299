<template>
  <div v-if="result === 'success'">
    {{__('Bienvenue, tu as bien été inscrit·e à la newsletter. 🎉')}}
  </div>
  <form 
    v-else
    action="https://makesense.us3.list-manage.com/subscribe/post-json?u=1fc4ae64ba9a767c92f9c2e4d&amp;id=b9cb4b39b6&amp;f_id=0078c4e1f0&" 
    target="_blank" 
    method="post"
    @submit.prevent="onSubmit">
    <input type="hidden" name="SIGNUP" id="MERGE3" :value="signUpSource">
    <input type="hidden" name="mc_signupsource" :value="signUpSource">
    <div class="newsletter-email" :class="size === 'big' ? 'newsletter-email--big' : ''">
      <mks-field 
        class="newsletter-email__field" 
        type="email" 
        :placeholder="__('chiche@makesense.org')" 
        autocapitalize="none" 
        autocorrect="off" 
        autofill="off"
        name="EMAIL" 
        id="MERGE0" 
        size="25" 
        :model-value="email"
      />
      <mks-button 
        class="newsletter-email__button" 
        :type="ctaColor || 'primary'" 
        input-type="submit" 
        size="small" 
        icon="arrow-right"
        :icon-right="true"
        :animate-icon="true"
        @click="track"
        :loading="loading"
        :disabled="loading"
      >{{ callToAction }}</mks-button>
    </div>
    <div class="newsletter__result" v-if="result === 'error'">
      {{__('Une erreur s\'est produite. Réessaye dans quelques instants.')}}
    </div>
  </form>
</template>
<script>
import MksField from "@DS/components/Field.vue";
import {mapGetters} from 'vuex';
export default {
  components: {MksField},
  props: {
    list: {
      type: String,
      default: 'https://makesense.us3.list-manage.com/subscribe/post'
    },
    signUpSource: {
      type: String,
      default: 'chiche'
    },
    callToAction: {
      type: String,
      default: 'S\'abonner'
    },
    ctaColor: {
      type: String,
      default: 'primary'
    },
    size: {
      type: String,
      default: 'normal'
    }
  },
  data() {
    return {
      result: null,
      loading: null
    }
  },
  computed: {
    ...mapGetters('user', ['userLoading', 'isLoggedIn', 'profile']),
    email() {
      if (this.isLoggedIn && this.profile) return this.profile.email;
      else return '';
    }
  },
  methods: {
    track() {
      this.$store.dispatch('analytics/track', {
        event: "Subscribe newsletter",
        data: {
          source: this.signUpSource
        }
      });
    },
    onSubmit(e) {
      this.loading = true;
      const signup = e.target;
      // besoin de formData pour récup l'email parce que this.email = undefined askip. 
      // peut etre a cause du computed
      const formData = new FormData(signup);
      const fields = {
        'SIGNUP': this.signUpSource,
        'EMAIL': formData.get('EMAIL')
      };
      
      this.$api.User.subscribeNewsletter({
        list: 'b9cb4b39b6',
        email: formData.get('EMAIL'),
        fields
      })
        .then(res => {
          console.log('res', res);
          this.loading = false;
          this.result = res.result;
          this.$emit('subscribed');
        })
        .catch(err => {
          console.log('err', err);
          this.loading = false;
          this.result = 'error'
        });
    }
  }
}
</script>
<style lang="scss">
.field-shift {
    left: -9999px; position: absolute;
}

html[dir="rtl"] .field-shift {
    right: -9999px;
    left: auto;
}
.newsletter-email {
  position: relative;

  &__field input{
    border-radius: 80px !important;
    font-size: $font-size-s !important;
    padding: 1.5rem !important;
    padding-right: 130px !important;

    @include breakpoint('small', 'max') {
      padding-right: 120px !important;
    }
  }

  &__button {
    position: absolute;
    right: $space-xs;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 100px !important;
  }

  &--big .newsletter-email__button {
    padding: $space-m !important;

    @include breakpoint('small', 'max') {
      margin-right: -7px;
    }
  }

  &--big .field {
    @include breakpoint('small') {
      font-size: 16px !important;
      padding: $space-l !important;
      // padding-bottom: $space-l !important;
    }
    
  }
  // display: flex;
  // width: 100%;
  // //justify-content: flex-end;
  // @include breakpoint('small', 'max') {
  //     flex-wrap: wrap;
  //     justify-content: flex-start;
  //   }
  // // align-items: end;

  // & > :first-child {
  //   flex: 1;
  //   margin-right: $space-m;
  //   min-width: 200px;

  //   @include breakpoint('small', 'max') {
  //     margin-bottom: $space-xs;
  //   }

  // }
}
</style>