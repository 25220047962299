export const appLayout = "chiche"

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"google-site-verification","content":"liSYj-CE_yvgcF2Wl5d8Q_jIMGlAMJTs7Mv01XxN9QA"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon-chiche.png"},{"rel":"preconnect","href":"https://fonts.gstatic.com"},{"rel":"stylesheet","href":"https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap\""}],"style":[],"script":[{"hid":"carbon","src":"https://unpkg.com/website-carbon-badges@1.1.3/b.min.js","defer":true}],"noscript":[],"title":"chiche_par_makesense"}

export const appPageTransition = {"name":"fade","mode":"out-in"}

export const appLayoutTransition = {"name":"fade","mode":"out-in"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'