<template>
  <time
    :class="{
      'event-date': true,
      'event-date--small': size === 'small'
    }"
    :datetime="$dayjs(date).format('YYYY-MM-DDThh:mm')">
    <mks-date-number color="tertiary" class="event__date">{{
      number
    }}</mks-date-number>
    <mks-heading v-if="showText" tag="h5" class="event-date__text">{{ text }}</mks-heading>
  </time>
</template>
<script>
import dateMixin from "@/mixins/date";
export default {
  mixins: [dateMixin],
  props: {
    date: {
      type: String
    },
    size: {
      type: String // small, normal, large
    },
    showText: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    number() {
      return this.getDateNumber(this.date);
    },
    text() {
      return this.$dayjs(this.date).format("DD MMM");
    }
  }
}
</script>
<style lang="scss">
.event-date {
  background: $color-neutral-100;
  border-radius: $border-radius-m;
  border: 1px solid $color-neutral-90;
  display: inline-flex;
  align-items:center;
  padding: $space-xs;

  .date {
    width: 35px !important;
    height: 35px !important;
    font-size: $font-size-l !important;
    transform: none !important;

  }

  .event-date__text {
    font-weight: $font-weight-extrabold;
    margin-left: $space-xs;
  }

  &--small {
    padding: $space-xxs;

    .event-date__text {
      font-size: $font-size-m;
    }

    .date {
      transform: scale(0.5);
    }
  }
}


</style>