<template>
  <div class="events-list" v-if="events.length && type === 'agenda'">
    <slot></slot>
    <div
      class="events__day"
      v-for="(day, index) in eventsByDay"
      :key="index"
    >
      <mks-spacer inline spacing="s" align="center" class="sticky-date events__day-date" >
        <mks-date-number>{{
          getDateNumber(day[0].date)
        }}</mks-date-number>

        <mks-text size="large" weight="bold"
          ><time
            :datetime="$dayjs(day[0].date).format('YYYY-MM-DDThh:mm')"
            class="header__date"
          >
            {{ relativeDate(day[0].date) }}
          </time></mks-text
        >
      </mks-spacer>
      <div v-if="(layout === 'simple' || layout === 'simple-show-past')" class="event__group--list mks-grid" name="fade">
        <EventCard
          class="event mks-col-12 mks-col-5@sm mks-col-4@md"
          v-for="event in day"
          :key="event._id"
          :event="event"
          :layout="layout"
          :target="target"
          :source="source"
          :show-like-on-cover="showLikeOnCover"
          :view-as-organizer="viewAsOrganizer"
          :show-if-event-is-past="showIfEventIsPast"
          @liked="$emit('liked', event._id)"
          @removed="$emit('removed', event._id)"
        ></EventCard>
      </div>
      <div v-else :class="`events__group ${eventSpacing === 'large' ? 'events__group--space-xxxl' : 'events__group--space-xl'}`">
        <EventCard
          class="event"
          v-for="event in day"
          :key="event._id"
          :event="event"
          :layout="layout"
          :target="target"
          :source="source"
          :show-like-on-cover="showLikeOnCover"
          :view-as-organizer="viewAsOrganizer"
          :show-if-event-is-past="showIfEventIsPast"
          @liked="$emit('liked', event._id)"
          @removed="$emit('removed', event._id)"
        ></EventCard>
      </div>
    </div>
  </div>
  <div class="events-list" v-else-if="events.length && type === 'grid'">
    <slot></slot>
    <div class="event__group--list events__group--space-l mks-grid" name="fade">
      <template v-if="$env.SITE === 'chiche' && !viewAsOrganizer">
        <div v-for="event in events" :key="event._id" class="event mks-col-12 mks-col-5@sm mks-col-4@md">
          <EventTeaser
            :event="event"
            :show-description="false"
            :target="target"
            :source="source"
            variant="flat"
            @liked="$emit('liked', event._id)"
            @removed="$emit('removed', event._id)"
          />
        </div>
      </template>
      <EventCard
        v-else
        class="event mks-col-12 mks-col-5@sm mks-col-4@md"
        v-for="event in events"
        :key="event._id"
        :event="event"
        :layout="layout"
        :target="target"
        :source="source"
        :show-like-on-cover="showLikeOnCover"
        :view-as-organizer="viewAsOrganizer"
        :show-if-event-is-past="showIfEventIsPast"
        @liked="$emit('liked', event._id)"
        @removed="$emit('removed', event._id)"
      ></EventCard>
      <div v-if="$slots.end" class="event mks-col-12 mks-col-5@sm mks-col-4@md">
        <slot name="end"></slot>
      </div>
    </div>
    <!-- <div v-else :class="`events__group ${eventSpacing === 'large' ? 'events__group--space-xxxl' : 'events__group--space-l'}`">
        <EventCard
          class="event"
          v-for="event in events"
          :key="event._id"
          :event="event"
          :layout="layout"
          :target="target"
          :source="source"
          :show-like-on-cover="showLikeOnCover"
          @liked="$emit('liked', event._id)"
          @removed="$emit('removed', event._id)"
        ></EventCard>
        <div v-if="$slots.end" class="event">
          <slot name="end"></slot>
      </div>
      </div> -->
  </div>
  <div class="events-list" v-else-if="events.length && type === 'line'">
    <slot></slot>
    <div class="event__group--list events__group--space-l" name="fade">
        <EventCard
          class="event"
          v-for="event in events"
          :key="event._id"
          :event="event"
          :layout="layout"
          :target="target"
          :source="source"
          :show-like-on-cover="showLikeOnCover"
          :view-as-organizer="viewAsOrganizer"
          :show-if-event-is-past="showIfEventIsPast"
          @liked="$emit('liked', event._id)"
          @removed="$emit('removed', event._id)"
        ></EventCard>
        <div v-if="$slots.end" class="event">
          <slot name="end"></slot>
        </div>
    </div>
  </div>
</template>

<script>
import dateMixin from "@/mixins/date"
import EventCard from "@/components/organisms/EventCard";

export default {
  name: "EventList",
  components: { EventCard },
  mixins: [dateMixin],
  props: {
      events: {
          type: Array,
          default: []
      },
      type: {
        type: String,
        default: 'agenda'
      },
      layout: {
        type: String,
        default: 'card'
      },
      eventSpacing: {
        type: String,
        default: 'large'
      },
      target: {
        type: String,
        default: '_self'
      },
      /** for tracking */
      source: {
        type: String
      },
      showLikeOnCover: {
        type: Boolean
      },
      viewAsOrganizer: {
        type: Boolean
      },
      showIfEventIsPast: {
        type: Boolean,
        default: true
      }
  },
  computed: {
    eventsByDay() {
      if (this.events.length) {
        const events = this.events.map((e) => {
          return {
            ...e,
            formattedDate: this.$dayjs(e.date).format("YYYY-MM-DD"),
          };
        });
        const grouped = this.groupBy(events, "formattedDate");
        return grouped || [];
      }
    },

  },
  methods: {
    groupBy(array, key) {
      return array.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },

  },
};
</script>

<style lang="scss" scoped>
.sticky-date {
  @include breakpoint('medium') {
    background: $color-neutral-100;
    position: sticky;
    top: var(--header-height);
    z-index: 999;
    @include inner-space('xs', 'b');
  }
}
.events__day{
  // not using spacer component on parent to avoid collision with container-spacing mixin
  padding-bottom: $space-l;
  margin-bottom: var(--space-xxl);
}
.events__day-date {
  text-transform: capitalize;
  margin-bottom: $space-m;
}
.event__group--list {
  --grid-gap: var(--space-m);
  @include breakpoint('medium') {
    --grid-gap: var(--space-xl);
  }
}
.events__group {
  &--space-l .event-card,
  &--space-l .event{
    margin-bottom: $space-l;
  }

  &--space-xxxl .event-card,
  &--space-xxxl .event{
    margin-bottom: $space-xl;
    @include breakpoint('small') {
      margin-bottom: $space-xxxl;
    }
  }
}
</style>
