<template>
  <div>
    <NuxtLayout :key="layout">
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
<script>
export default {
  async setup() {
    const nuxtApp = useNuxtApp();
    if (process.server) {
      await nuxtApp.$store.dispatch('builder/init');
    }
  },
  computed: {
    layout() {
      return this.$route.meta.layout;
    }
  }
}
</script>

<style lang="css" src="@DS/tokens/generated/themes/base.css"></style>
<style lang="css" src="@DS/tokens/generated/themes/chiche.css"></style>
<!-- <style lang="scss" src="assets/style/shared.scss"></style> -->
<style lang="scss" src="assets/style/global.scss"></style>
<style lang="scss" src="assets/style/builder.scss"></style>
<style lang="scss" src="assets/style/tippy.scss"></style>
<style lang="scss" src="assets/style/toast.scss"></style>
<style lang="scss" src="assets/style/transition.scss"></style>
