<template>
  <blockquote class="mks-component quote" v-if="text" :style="`--quote-theme: ${color}`">
    <div v-if="text" v-html="text"></div>
    <div class="author-block mks-component" v-if="author" v-html="author"></div>
  </blockquote>
  <blockquote v-else class="quote mks-component" :style="`--quote-theme: ${color}`">
    <slot></slot>
  </blockquote>
</template>
<script>
export default {
  props: {
    text: {
      type: String
    },
    author: {
      type: String
    },
    color: {
      type: String,
      default: 'var(--color-secondary)'
    }
  }
}
</script>
<style lang="scss">

blockquote.quote {
  @include title-xsmall;
  font-weight: 900;
  color: $color-primary;
  padding-left: $space-l;
  position: relative;

  & > p, span, a {
    @include title-xsmall;
  }
  .author-block {
    @include title-xsmall;
    font-weight: normal;
    font-size: var(--font-size-m) !important;
    font-family: var(--font-heading);
    line-height: var(--space-l);
}

  &:before {
    content: "";
    display: inline-block;
    width: 8px;
    background: var(--quote-theme);
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: 10px;
  }
}
</style>