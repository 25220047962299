<template>
  <MksEventLineCard
    :class="`event-card--${layout}`"
    v-if="layout.startsWith('line')"
    :title="event.title[event.lang]"
    :cover="imageUrl"
    :date="event.date"
    :duration="getDurationMinutes(event.date, event.startDate)"
    :metas="[
      event.type ? event.type.name[lang] : undefined,
      location
    ]"
    :link="localePath(`/e/${event.slug}`)"
    :cliquable="layout === 'line' ? false : true"
    :showCover="layout === 'line' ? true : true"
    :showCallToAction="layout === 'line' ? true : false"
    :showDate="layout === 'line' ? false : true"
  >
  </MksEventLineCard>
  <div v-else :class="`event-card mks-grid event-card--${layout} ${pastEvent && showIfEventIsPast ? 'past ': ''}${event.status === 'cancelled' ? 'cancelled ': ''}`">
    <EventCardCover
      class="event__cover-wrapper"
      :event="event"
      :eventLink="eventLink"
      :show-cover-preview="showCoverPreview"
      :target="target">
      <div class="cover__like">
        <like
          v-if="(!userLoading && isLoggedIn && showLikeOnCover)"
          :id="event._id"
          @liked="$emit('liked', event._id)"
          @removed="$emit('removed', event._id)"
        ></Like>
      </div>
      <div class="cover__tags">
        <mks-link :to="localePath(`/organize/event/${event.slug}/attendees`)" type="menu">
          <mks-tag color="atlantis" v-if="viewAsOrganizer && event.attendees && event.attendees.length" is-cliquable>
            <mks-icon size="16" type="users" />&nbsp;{{ event.attendees.length }}
          </mks-tag>
        </mks-link>
        <mks-link :to="localePath(`/organize/event/${event.slug}/feedbacks`)" type="menu">
          <mks-tag color="cerise" v-if="viewAsOrganizer && event.feedbacks && event.feedbacks.length" is-cliquable>
            <mks-icon size="16" type="star" />&nbsp;{{ event.feedbacks.length }}
          </mks-tag>
        </mks-link>


      </div>
    </EventCardCover>
    <mks-spacer v-if="layout === 'ticket'" spacing="s" class="event__content">
      <Date :event="event" big showDuration></Date>
      <mks-text
        size="small"
        weight="semibold"
        v-if="hasAddress"
        ><mks-icon size="16" type="map-pin"></mks-icon>
        {{ event.address[lang].formattedAddress }}</mks-text
      >
      <mks-text
        v-else-if="event.visio"
        size="small"
        weight="semibold"
        color="primary"
        ><mks-icon type="video" size="15"></mks-icon>
        {{ __("Via vidéo-conférence") }}</mks-text
      >
      <mks-spacer-item
        v-if="!pastEvent"
        push="bottom"
      >
        <EventCardAvailableTickets v-if="showAvailableTickets && !alreadyRegistered" :event="event"/>
        <EventCardParticipateButton
          :event="event"
          :showParticipateButton="showParticipateButton"
          :source="source"
        />
      </mks-spacer-item>
    </mks-spacer>
    <mks-spacer v-else spacing="s" class="event__content">
      <EventCardTags v-if="layout === 'card'" :event="event"/>
      <template v-if="preview">
        <mks-heading v-if="event.title" tag="h6" class="event__title">{{ event.title[event.lang] }}</mks-heading>
        <span v-else class="skeleton"></span>
      </template>
      <mks-heading
        v-else
        :tag="layout.startsWith('simple') ? 'h6' : 'h4'"
        class="event__title"
        ><router-link :to="eventLink" :target="target">{{
          event.title[event.lang]
        }}</router-link></mks-heading
      >
      <div
        class="event__description"
        v-if="event.description && !layout.startsWith('simple')"
        v-html="sanitizedDescription"
      />
      <Date :event="event" showLocation></Date>
      <mks-spacer-item
        v-if="!layout.startsWith('simple')"
        push="bottom"
      >
        <EventCardParticipateButton
          :event="event"
          :showParticipateButton="showParticipateButton"
          :showIfEventIsPast="showIfEventIsPast"
          :source="source"
        >
          <EventCardAvailableTickets v-if="showAvailableTickets"/>
        </EventCardParticipateButton>
      </mks-spacer-item>
      <mks-link v-if="viewAsOrganizer" :to="localePath(`/organize/duplicate/${event.slug}`)">
        <mks-button size="full" type="neutral">{{__('Dupliquer')}}</mks-button>
      </mks-link>
    </mks-spacer>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import dateMixin from "@/mixins/date";
import Date from "@/components/atoms/Date.vue";
import Like from "@/components/organisms/Like.vue";
import MksField from "@DS/components/Field.vue";
import MksEventLineCard from "@DS/components/EventLineCard.vue";
import EventCardRegistrationConfirmation from './EventCard/RegistrationConfirmation.vue';
import EventCardTags from './EventCard/Tags.vue';
import EventCardAvailableTickets from './EventCard/AvailableTickets.vue';
import EventCardParticipateButton from './EventCard/ParticipateButton.vue';
import EventCardCover from './EventCard/Cover.vue';

export default {
  components: {
    Date,
    Like,
    MksField,
    MksEventLineCard,
    EventCardRegistrationConfirmation,
    EventCardTags,
    EventCardAvailableTickets,
    EventCardParticipateButton,
    EventCardCover
  },
  mixins: [dateMixin],
  props: {
    event: {
      type: Object
    },
    layout: {
      type: String,
      default: "card",
      validator: value =>
        ["card", "simple", "simple-show-past", "ticket", "line", "line-simple", "organizer", 'organizer-active'].indexOf(value) !== -1
    },
    target: {
      type: String,
      default: "_self"
    },
    /** for tracking purposes */
    source: {
      type: String
    },
    showLikeOnCover: {
      type: Boolean
    },
    viewAsOrganizer: {
      type: Boolean
    },
    showIfEventIsPast: {
      type: Boolean,
      default: true
    },
    preview: {
      type: Boolean,
      default: false
    },
    showCoverPreview: {
      type: Boolean,
      default: false
    }
    // pour iframe / simple / light
    // layout: {
    //     type: String,
    //     default: 'card'
    // }
  },
  computed: {
    ...mapGetters("user", ["userLoading", "isLoggedIn", "profile"]),
    pastEvent() {
      if (this.preview) return;
      if (this.event.endDate) return this.$dayjs(this.event.endDate).isBefore(this.$dayjs());
      else return this.$dayjs(this.event.date).isBefore(this.$dayjs());
    },
    alreadyRegistered() {
      if (this.preview) return;
      if (this.event.registerExternal) return false;
      if (!this.profile) return false;
      if (!this.event.attendees || this.event.attendees.length < 1) return false;
      return this.event.attendees.filter(a => {
        if (!a.user) return false;
        return a.user === this.profile._id || a.user._id === this.profile._id
      }).length > 0 || this.$store.state.event.attended.indexOf(this.event._id) > -1;
    },
    sanitizedDescription() {
      return this.event.description && this.event.description[this.event.lang]
        ? this.event.description[this.event.lang].replace(/<[^>]+>/g, " ")
        : "";
    },
    day() {
      if (!this.event.date) return;
      return this.getDateNumber(this.event.date);
    },
    dayTime() {
      if (!this.event.date) return;
      return this.relativeDate(this.event.date, true);
    },
    date() {
      if (!this.event.date) return;
      return this.getDate(this.event.date);
    },
    fullDate() {
      if (!this.event.date) return;
      return this.getFullDate(this.event.date);
    },
    time() {
      if (!this.event.date) return;
      return this.getTime(this.event.date);
    },
    availableTickets() {
      if (this.preview) return;
      return (
        parseInt(this.event.maxAttendees) -
        parseInt((this.event.attendees && this.event.attendees.length) || 0)
      );
    },
    imageUrl() {
      if (
        this.event.cover &&
        this.event.cover[this.event.lang] &&
        this.event.cover[this.event.lang].url
      ) {
        return this.checkURL(this.event.cover[this.event.lang].url)
          ? this.event.cover[this.event.lang].url
          : "/placeholder.png";
      }
      return "/placeholder.png";
    },
    hasAddress() {
      return (
        this.event.address &&
        this.event.address[this.lang] &&
        this.event.address[this.lang].city
      );
    },
    eventLink() {
      if (this.preview) return undefined;
      if (this.viewAsOrganizer) return this.localePath('/organize/event/'+this.event.slug+'/dashboard');
      else return this.localePath(`/e/${this.event.slug}`)
    },
    externalEventLink() {
      if (this.preview) return '';
      return this.$env.apiUrl + '/v1/events/redirect/' + this.event._id + '?lang=' + this.lang + "&user=" + this.email;
    },
    location() {
      if (this.event.visio) return this.__('Online');
      else if (this.hasAddress) return this.event.address[this.lang].city;
    },
    showParticipateButton() {
      if (this.preview) return false;
      return !this.pastEvent && !this.alreadyRegistered &&
        ((!this.event.maxAttendees || this.availableTickets > 0) || this.event.registerExternal);
    },
    showAvailableTickets() {
      return this.layout === 'ticket' && !this.event.registerExternal && (!this.event.maxAttendees || this.availableTickets > 0) && !this.alreadyRegistered;
    }
  },
  created() {
    if (this.layout === "ticket") this.registering = true;
  },
  methods: {
    checkURL(url) {
      return url.match(/\.(jpeg|jpg|gif|png)$/i) != null;
    },

  },
};
</script>

<style lang="scss" scoped>
.event-card {
  position: relative;
  color: inherit;
  transition: all 0.3s ease-out;
  width: 100%;
  --grid-gap: var(--space-s);
  border-radius: $border-radius-l;
  //@include elevated-xxl;
  @include breakpoint("small", "max") {
    @include elevated-xxl;
  }
  @include breakpoint("small") {
    --grid-gap: var(--space-xl);
  }

  .cancelled :deep(img) {
    transition: all 0.5s ease;
    filter: grayscale(90%);
    &:hover {
      filter:grayscale(30%);;
    }
  }

  &:hover {
    //background: $color-neutral-90;
    // @include elevated;
    // border-radius: $border-radius-l;
  }
  .event__cover-wrapper {
    position: relative;
    @include col(12);
    @include breakpoint("small") {
      @include col(6);
    }
  }

  .event__content {
    @include col(12);
    @include breakpoint("small") {
      @include col(6);
    }
  }

  &--card {
    .event__content {
      @include breakpoint("small", "max") {
        @include inner-space("s");
        @include inner-space("0", "t");
      }
    }
  }

  &--ticket {
    background: $color-neutral-100;
  }

  &--simple,
  &--simple-show-past {
    display: block;
    background: $color-neutral-100;
    @include elevated-xxl;

    :deep(.event__cover) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .event__content {
      @include inner-space;
    }
  }

  &--line,
  &--line-simple {
    :deep(.event__cover-image) {
      border-radius: $space-xxs;
    }
  }
}
.past,
.event-card.cancelled {
  :deep(img) {
    transition: all 0.5s ease;
    filter: grayscale(90%);
    &:hover {
      filter:grayscale(30%);;
    }
  }
}

.cover__like {
  position: absolute;
  top: 25px;
  right: $space-m;
  z-index: 500;
}

.cover__tags {
  position: absolute;
  bottom: -10px;
  left: $space-m;
  z-index: 500;
}


.event__description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
  @include text-small;
  color: $color-text-light;
}

.event__title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  transition: all 0.2s ease;
  & > a {
    color: $color-text;
  }

  &:hover {
    text-decoration: underline !important;
  }
}

.event__date {
  @include flex-center;
  justify-content: flex-start;
}

.event__content {
  display: flex;
  flex-direction: column;

  .event-card--ticket & {
    @include breakpoint("small", "max") {
      @include inner-space("s");
      @include inner-space("0", "t");
    }

    @include breakpoint("small") {
      @include inner-space("l");
      @include inner-space("0", "l");
      @include inner-space("0", "b");
    }
  }
  //padding: 0 $space-m;
  //padding-bottom: 0;
}

.skeleton {
  display: inline-block;
  height: $space-s;
  width: 100%;
  min-width: 150px;
  max-width: 300px;
  border-radius: $border-radius-full;
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.8) 50%,
      rgba(255, 255, 255, 0) 80%
    ),
    $background-color-softest;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: shine 1s infinite;
}
@keyframes shine {
  to {
    background-position: 100% 0, /* move highlight to right */ 0 0;
  }
}
</style>
