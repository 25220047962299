<template>
  <div 
    class="page-header" 
    :class="{
      'page-header--chiche': $env.SITE === 'chiche',
      'page-header--intl': $env.SITE === 'international',
    }" 
    :style="colors"
  >
    <nuxt-img 
      v-if="image" 
      format="webp" 
      class="page-header__image" 
      :src="image" 
      :alt="imageAlt" 
      sizes="200vw xs:200vw sm:200vw md:200vw lg:200vw"
      />
    <div class="page-header__content mks-section-container">
      <slot></slot> 
    </div>
    
  </div>

</template>
<script>
import colorsMixin from '@/mixins/colors'
export default {
  mixins: [colorsMixin],
  props: {
    background: {
      type: String,
      default: 'color-primary' // variable from DS or hexa code
    },
    textColor: {
      type: String,
      default: 'color-neutral-100'
    },
    image: {
      type: String
    },
    imageAlt: {
      type: String
    }
  },
  computed: {
    colors() {
      let styles = '';
      if (this.textColor) styles += ' ' + this.getColorStyles('color', this.textColor);
      if (this.background && !this.image) {
        styles += this.getColorStyles('background', this.background);
      }
      return styles;
    }
  },
}
</script>
<style lang="scss">
.page-header {
  position: relative;
  padding-bottom: $space-xl;
  border-bottom-left-radius: 80px;
  min-height: 300px;
  z-index: 1;

  &--chiche {
    padding-top: 135px; // default
    padding-top: var(--header-height);
  }

  &--intl {
    padding-top: $space-xl;
  }

  & > * {
    z-index: 1;
  }
}

.page-header__image {
  position: absolute;
  top: 0;
  z-index: -1 !important;
  object-fit: cover;
  border-bottom-left-radius: 80px;
  width: 100%;
  height: 100%;
}
</style>