import LFSR from 'lfsr';
var DEFAULT_SEED = 149304961039362642461,
    REGISTER_LENGTH = 31,
    FLUSH_TIMES = 20;

/**
 * @param {Number} [seed] value for LFSR
 */
function PRNG(seed) {
    this.lfsr = new LFSR(REGISTER_LENGTH, seed || DEFAULT_SEED);
    // flush initial state of register because thay may produce
    // weird sequences
    this.lfsr.seq(FLUSH_TIMES * REGISTER_LENGTH);
}

PRNG.prototype = {
    /**
     * @param {Number} min value
     * @param {Number} max value
     */
    rand: function(min, max) {
        // if invoked with one value consider min to be 0
        // rand(16) == rand(0, 16)
        if (!max) {
            max = min;
            min = 0;
        }

        // swap if min > max
        if (min > max) {
            var t = max;
            max = min;
            min = t;
        }

        var offset = min;

        var bits = ~~this._log2(max - offset) + 1,
            random;
        do {
            random = this.lfsr.seq(bits);
        } while (random > (max - offset));
        return random + offset;
    },
    _log2: function(n) {
        return Math.log(n) / Math.LN2;
    }
};


function prngShuffle (array, seed) {
  const prng = new PRNG(seed);
  const length = array == null ? 0 : array.length;
  if (!length) {
    return [];
  }
  let index = -1;
  const lastIndex = length - 1;
  const result = array.slice();
  while (++index < length) {
    const rand = prng.rand(index, lastIndex);
    const value = result[rand];
    result[rand] = result[index];
    result[index] = value;
  }
  return result;
}

export default defineNuxtPlugin(() => {
  return {
    provide: {
      prngShuffle
    }
  }
});

