<template>
  <div :class="`mks-component newsletter-card newsletter-card--size-${size} newsletter-card--theme-${theme} ${article && 'newsletter-card--article'}`">
    <nuxt-img 
      v-if="image && size === 'normal'"
      class="newsletter-card__image"
      :src="image" 
      alt="le cachet du lundi" 
      format="webp"
      sizes="xs:114px sm:200px lg:350px"
      loading="lazy">
    </nuxt-img>
    <div class="newsletter-card__content">
      <Heading 
        tag="h4" 
        tag-class="h4"
        color="inherit" 
        class="newsletter-card__heading"
        :textBefore="titleBefore"
        :textBean="titleBean"
        :textAfter="titleAfter"
        >
      </Heading>
      <div v-if="!hideDescription && !!description" v-html="description" class="newsletter-card__description"></div>
      <div class="newsletter-card__form hide-on-mobile"><NewsletterField :sign-up-source="source" :call-to-action="callToAction" /></div>
    </div>
    <div class="hide-on-desktop newsletter-card__form"><NewsletterField :sign-up-source="source" :call-to-action="callToAction"/></div>
    <div class="newsletter-card__close" v-if="close" @click="$emit('close')">
      <mks-icon type="x"></mks-icon>
    </div>
  </div>
</template>
<script>

export default defineNuxtComponent({
  emits: ['close'],
  props: {
    titleBefore: {
      type: String,
      default: 'Le Cachet du lundi est mort, vive la '
    },
    titleBean: {
      type: String,
      default: 'chiche'
    },
    titleAfter: {
      type: String,
      default: 'letter !'
    },
    description: {
      type: String,
      default: 'Notre newsletter est un moyen simple et efficace de rester informé(e).'
    },
    hideDescription: {
      type: Boolean,
      default: false
    },
    callToAction: {
      type: String,
      default: 'S\'abonner'
    },
    image: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'normal' // 'small', 'normal'
    },
    theme: {
      type: String,
      default: 'neutral' // 'neutral', 'parsley'
    },
    source: {
      type: String
    },
    article: {
      type: Boolean,
      default: false 
    },
    close: {
      type: Boolean,
      default: false 
    }
  }
})
</script>
<style lang="scss">
.newsletter-card {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  
  &__image {
    max-width: 100px;
    margin-right: $space-m;
  }

  &__heading {
    margin-bottom: $space-s;
    font-weight: $font-weight-black !important;
  }
  &__description {
    margin-bottom: $space-m;
  }

  &__content {
    flex-shrink: 1;
    flex-grow: 1;
    flex-basis: 60%;
  }

  &__form {
    flex-grow: 1;
  }

  &__close {
    position: absolute;
    top: $space-s;
    right: $space-s;
    cursor: pointer;
  }

  &--size-small {
    padding: $space-m;
    max-width: 400px;

    @include breakpoint('small') {
      padding: $space-l;
    }
  }
  &--size-normal {
    padding: $space-m;

    @include breakpoint('small') {
      padding: $space-xl;
    }
  }
  &--theme-neutral {
    @include card-neutral;
    color: $color-neutral-0;
  }

  &--theme-parsley {
    @include card-color($color-parsley, $color-default);
  }
}
</style>