<template>
  <div spacing="small">
    <mks-tag v-if="event.type && event.type.name" color="parsley">{{
      event.type.name[lang]
    }}</mks-tag>
    <mks-tag v-if="event.topic && event.topic.name" color="parsley">{{
      event.topic.name[lang]
    }}</mks-tag>
  </div>
</template>
<script>
export default {
  props: ['event']
}
</script>