<template>
  <component :is="sponsoredByLink ? 'a' : 'span'" :href="sponsoredByLink" class="mks-component sponsored-by">
    <mks-icon size="25" type="heart" color="negative" class="sponsored-by__icon"></mks-icon> {{ sponsoredByText }}
    <img :src="sponsoredByLogo" :alt="`Logo de ${sponsoredByPartner}`" class="sponsored-by__logo"/> 
  </component>
</template>
<script>

export default defineNuxtComponent({
  props: {
    link: String,
    logo: String,
    partner: String,
    text: {
      type: String,
      default: 'En partenariat avec '
    },
    sponsoredBy: Object,
    theme: String
  },
  data() {
    return {
      content: null
    }
  },
  computed: {
    sponsoredByLink() {
      return this.link || (this.content && this.content.data.link);
    },
    sponsoredByText() {
      return this.text || (this.content && this.content.data.text);
    },
    sponsoredByPartner() {
      return this.partner || (this.content && this.content.data.partner);
    },
    sponsoredByLogo() {
      if (this.logo) return this.logo;
      else if (this.theme === 'light') return this.content && this.content.data.logoLight;
      else return this.content && this.content.data.logo;
    }
  },
  async created() {
    if (!this.sponsoredBy) return;
    if (this.sponsoredBy && !this.sponsoredBy.value) {
      this.content = await this.$builder.getRef(this.sponsoredBy);
    } else if (this.sponsoredBy.value) {
      this.content = this.sponsoredBy.value;
    }
  },
})
</script>
<style lang="scss" scoped>
.sponsored-by {
  color: inherit;
  @include text-small;
  display: flex;
  align-items: center;
  gap: 7px;
  flex-wrap: wrap;
}

.sponsored-by__icon {
  fill: $color-danger;
}
.sponsored-by__logo {
  padding-left: $space-xxs;
  max-height: 30px;
  max-width: 200px;
}
</style>