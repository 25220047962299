export default defineNuxtPlugin(async (NuxtApp) => {

  function localePath(path) {
    if (typeof path === 'string') {
      if (path.match(/^\/e\/(.+)$/)) {
        return '/events' + path;
      }
    }
    else if (typeof path === 'object') {
      if (path.name === 'index') {
        path.name = 'events';
      }
    }

    return path;
  }
  if (!NuxtApp.$i18n) {
    NuxtApp.$i18n = {
      locale: 'fr',
      setLocale: () => {}
    }
    NuxtApp.vueApp.mixin({
      methods: {
        localePath
      }
    });
    return {
      provide: {
        localePath
      }
    }
  }
});
