import striptags from "striptags";

export default {
  data() {
    return {
      metaContent: null,
    };
  },
  methods: {
    getHead(data, structuredDataList) {
      this.metaContent = data;
      if (!this.metaContent) return;

      let queryString = '';
      if (this.$route.path.startsWith('/events')  || this.$route.path.startsWith('/media')) {
        if (this.$route.query.page) queryString = `?page=${this.$route.query.page}`;
      }

      const head = {
        title: this.metaTitle,
        meta: [
          { hid: "title", name: "title", content: this.metaTitle },
          { hid: "og:title", name: "og:title", content: this.metaTitle },
          {
            hid: "twitter:title",
            name: "twitter:title",
            content: this.metaTitle,
          },
          {
            hid: "description",
            name: "description",
            content: this.metaDescription,
          },
          {
            hid: "og:description",
            name: "og:description",
            content: this.metaDescription,
          },
          {
            hid: "twitter:description",
            name: "twitter:description",
            content: this.metaDescription,
          },
          {
            hid: "image",
            name: "image",
            property: "og:image",
            content: this.metaImg,
          },
          { hid: "og:image", name: "og:image", content: this.metaImg },
          { hid: "og:image:width", name: "og:image:width", content: 1280 },
          { hid: "og:image:height", name: "og:image:height", content: 800 },
          {
            hid: "twitter:image",
            name: "twitter:image",
            content: this.metaImg,
          },
        ],
        link: [
          {
            rel: 'canonical',
            href: this.$env.eventsUrl + 
              this.$route.path + queryString
          },
        ],
        script: [
          {
            type: "application/ld+json",
            children: [this.organizationStructuredData]
          }
        ]
      };

      if (structuredDataList) {
        const list = this.addStructuredData(structuredDataList);
        head.script[0].children.push(...list);
      }

      return head;
    },
    getEventsStructuredData(events) {
      const eventsScript = events.map(e => {
        // console.log('e', e)
        return this.constructEventStructuredData(e);
      });
      return { 
          script: [
          {
            type: "application/ld+json",
            children: eventsScript
          }
        ]
      };
    },
    addStructuredData(structuredDataList) {
      const list = [];
      structuredDataList.forEach(structuredData => {
        const content = structuredData.data;

        if (structuredData.type === 'search') {
          list.push(this.searchStructuredData);
        } 
        if (structuredData.type === 'article') {
          list.push(this.constructArticleStructuredData(content));
        }
        // if (structuredData.type === 'event') {
        //   list.push(this.constructEventStructuredData(content));
        // }
      });
      return list;
    },
    constructEventStructuredData(event) {
      let organizer;
      if (event.user && event.user.length) {
        organizer = event.user[0].displayname || (event.user[0].firstName + ' ' + event.user[0].lastName);
      }
      else if (event.source && event.source.user) {
        organizer = event.source.user;
      }
      return {
        "@context": "http://schema.org",
        "@type": "Event",
        about: event.topic && event.topic.name ? event.topic.name[event.lang] : '',
        additionalType: event.type && event.type.name ? event.type.name[event.lang] : undefined,
        description: striptags(
          event.description &&
            event.description[event.lang] &&
            event.description[event.lang].substring(0, 250)
        ),
        duration: "",
        endDate: this.$dayjs(event.endDate).format('YYYY-MM-DD'),
        eventAttendanceMode: event.visio
          ? "https://schema.org/OnlineEventAttendanceMode"
          : "https://schema.org/OfflineEventAttendanceMode",
        image: event.cover && event.cover[event.lang] ? event.cover[event.lang].url : undefined,
        inLanguage: event.lang,
        isAccessibleForFree: true,
        location: {
          address: event.visio ? undefined : event.address ? event.address[event.lang].formattedAddress : undefined,
        },
        maximumAttendeeCapacity: event.maxAttendees,
        name: event.title[event.lang],
        organizer: organizer,
        startDate: this.$dayjs(event.date).format('YYYY-MM-DD')
      };
    },
    constructArticleStructuredData(content) {
      if (!content) return;
      let author;
      if (content.data.auteurice && content.data.auteurice.value) {
        author = content.data.auteurice.value.data.name || 
          (content.data.auteurice.value.data.firstname + ' ' + content.data.auteurice.value.data.lastname)
      }
      return {
        "@context": "http://schema.org",
        "@type": "Article",
        "headline": content.data.title,
        "image": content.data.image,
        "author": {
          "@type": "Person",
          "name": author
        },
        "datePublished": this.$dayjs(content.firstPublished).format('YYYY-MM-DD'),
        "dateModified": this.$dayjs(content.lastUpdated).format('YYYY-MM-DD'),
      };
    },
  },
  
  computed: {
    titleMetaPrefix() {
      return "";
    },
    site() {
      if (this.$env.SITE === "chiche") return "chiche_by_makesense";
      else return this.__("events that makesense");
    },
    metaTitle() {
      if (!this.metaContent) return;
      // metaData.title || metaData.titleSeo
      let title =
        this.metaContent.titleSeo ||
        this.metaContent.metaTitle ||
        this.metaContent.title;
      if (!title) return;
      title = title.replaceAll('&nbsp;', ' ');
      let shortenedTitle = title.substring(0, 70) + (title.length >= 71 ? "...": '');
      return (
        this.titleMetaPrefix +
        shortenedTitle 
      );
    },
    metaImg() {
      if (!this.metaContent) return;
      const image =
        this.metaContent.imageSeo ||
        this.metaContent.metaImage ||
        this.metaContent.image;
      if (image) return image;
      else return this.$env.logoUrl;
    },
    metaDescription() {
      if (!this.metaContent) return;
      const desc =
        this.metaContent.descriptionCourte ||
        this.metaContent.descriptionSeo ||
        this.metaContent.metaDescription ||
        this.metaContent.description;
      return striptags(desc && desc.substring(0, 150));
    },
    organizationStructuredData() {
      return {
        "@context": "http://schema.org",
        "@type": "Organization",
        "name": "makesense",
        "url": "france.makesense.org",
        "logo": this.$env.logoUrl,
        address: '11 rue Biscornet, 75012 Paris',
        "contactPoint": {
          "@type": "ContactPoint",
          "email": "chiche@makesense.org",
          "contactType": "Service client"
        }
      }
    },
    searchStructuredData() {
      return {
        "@context": "https://schema.org",
        "@type": "WebSite",
        "url": this.$env.eventsUrl,
        "name": "Chiche par makesense",
        "potentialAction": {
          "@type": "SearchAction",
          "target": `${this.$env.eventsUrl}/search?q={query}`,
          "query": "required",
          "query-input": "required name=query"
        }
      }
    }
  },
};
