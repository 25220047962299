import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin((NuxtApp) => {
  if (NuxtApp.$env.env !== "development") {
    Sentry.init({
      app: NuxtApp.vueApp,
      dsn: "https://90fab1398baaf458b3866ec361e938d2@o4507072496926720.ingest.de.sentry.io/4507072504266832",
      integrations: [
        Sentry.browserTracingIntegration({router: NuxtApp.$router}),
        Sentry.replayIntegration(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/api\.chiche\.makesense\.org/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }
});
