<template>
  <div class="location">
    <div ref="places"></div>
    <div class="location__address" @click="isOpen = true">
      <mks-icon class="location__icon" type="map-pin" width="19"></mks-icon>
      <input
        ref="autocomplete"
        v-show="open"
        type="search"
        class="location__input -style-default"
        :placeholder="placeholder"
        :disabled="!googleReady || disabled"
        v-model="location"
        @keydown="onEdit"
        @keydown.enter="onBlur"
        @blur="onBlur"
        @focus="onFocus"
        @change="$emit('change')"

      />
      <!-- <div
        v-on:click="onSave"
        class="location__save"
        v-if="showSave && !!location"
      >
        {{__('Enregistrer')}}
      </div>
      <div
        v-on:click="onUpdate"
        class="location__update"
        v-if="showUpdate && !!location"
      >
        {{__('Modifier')}}
      </div> -->
      <slot>
        <div
          v-on:click="onLocationClear"
          class="location__remove"
          v-if="!!location && !disabled"
        >
          <mks-icon type="x-circle"></mks-icon>
        </div>
      </slot>

    </div>
    <div class="location__predictions item__panel" v-if="showPanel">
      <div class="panel panel__lines">
        <div v-if="showOnline" class="panel__online" @click="chooseOnline">
          <mks-icon type="video" width="17" color="inherit"></mks-icon>
          &nbsp;{{ _x("En ligne", "Location Filter") }}
        </div>

        <template v-if="locationPredictions && locationPredictions.length > 0">
          <hr v-if="showOnline" class="divider"/>
          <div
            v-for="prediction in locationPredictions"
            :key="prediction.place_id"
            @click="chooseLocation(prediction)"
          >
            <mks-icon type="map-pin" width="19" color="inherit"></mks-icon>
            &nbsp;{{ prediction.description }}
          </div>
        </template>
        <template v-else-if="!locationPredictions || (locationPredictions.length === 0) && modelValue !== ''">
          <hr v-if="showOnline" class="divider"/>
          <div class="no-select" >
            {{ _x("Pas de résultats", "recherche ville pays pas de résultats") }}
          </div>
        </template>

        <!-- <div class="no-select" v-else><mks-text color="light" >{{ _x("Cherche par ville ou pays...", "recherche ville pays placeholder") }}</mks-text></div> -->
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import debounce from "@/helpers/debounce.js";
import MksField from "@DS/components/Field.vue";

export default {
  name: "FieldLocation",
  components: {
    MksField
  },
  props: {
    id: {
      required: true,
      type: String
    },
    name: {
      required: false,
      type: String
    },
    open: {
      required: false,
      type: Boolean,
      default: true
    },
    modelValue: {
      required: true
    },
    description: {
      type: String,
      required: false
    },
    label: {
      type: String,
      default: ""
    },
    message: {},
    placeholder: {
      type: String,
      required: false,
      default: ""
    },
    radius: {
      type: Boolean,
      required: false,
      default: false
    },
    showOnline: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    resultTypes: {
      type: Array,
      default: () => ['(regions)']
    },
    defaultPredictions: {
      type: Array,
      default: null
    },
    showUserCityAsPrediction: {
      type: Boolean,
      default: true
    }
  },
  data: function() {
    return {
      theValue: null,
      googleInput: null,
      editionMode: false,
      googleReady: false,
      googleService: null,
      placesService: null,
      ready: false,
      locationPredictions: [],
      showPanel: false,
      location: null
    };
  },
  computed: {
    ...mapGetters('user', ['userLoading', 'isLoggedIn', 'profile']),
    defaultPredictionsValue() {
      return this.defaultPredictions || [
        { description: this._x("Paris, France", "Location suggestion 1")},
        { description: this._x("Lyon, France", "Location suggestion 2") }
      ];
    }
  },
  created() {
    this.setInitialValue();
    this.setPredictions();
  },
  mounted: function() {
    if (!process.server) {
      if (typeof google === "undefined") {
        const script = document.createElement("script");
        script.onload = this.googleIsReady;
        script.type = "text/javascript";
        // script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.googleApiKey}&libraries=places&language=${this.$store.getters["getLocale"]}`;
        script.src = `https://maps.googleapis.com/maps/api/js?key=${this.$env.google.apiKey}&libraries=places&language=${this.lang}}`;
        document.head.appendChild(script);
      } else {
        this.googleIsReady();
      }
    }
  },
  methods: {
    setInitialValue(){
      if (this.modelValue && this.modelValue.formattedAddress !== undefined) {
        if (this.modelValue.name) this.location = this.modelValue.name + ', '+ this.modelValue.formattedAddress;
        else this.location = this.modelValue.formattedAddress;
      } else if (this.modelValue && this.modelValue.visio) {
        this.location = this._x("En ligne", "Location Filter");
      }
      else if (
        typeof this.modelValue === "object" &&
        Object.keys(this.modelValue).length === 0
      ) {
        this.location = "";
      }
      else this.location = this.modelValue;
    },
    googleIsReady: function() {
      this.googleReady = true;
    },
    onScriptLoaded: function() {
      this.googleService = new google.maps.places.AutocompleteService();
      this.placesService = new google.maps.places.PlacesService(this.$refs.places);

      this.$nextTick(() => {
        this.ready = true;
      });
    },
    onFocus: function() {
      if (!this.ready) this.onScriptLoaded();
      this.showPanel = true;
    },
    onLocationClear: function() {
      this.location = "";
      this.theValue = "";
      this.setPredictions();
      this.$emit("change");
    },
    onEdit: function() {
      this.editionMode = true;
      if (this.$refs.autocomplete.value) this.getPredictions();
      else this.setPredictions();
    },
    onBlur: function() {
      this.$nextTick(function() {
        setTimeout(() => {
          this.setPredictions();
          this.showPanel = false;
          // if (this.editionMode) {
          //   this.$refs.autocomplete.value = this.getFormattedAddress;
          //   this.$emit("blur");
          // }
        }, 200);
      });
    },
    getPredictions: debounce(function () {
      if (!this.$refs.autocomplete.value) return;
      this.googleService.getPlacePredictions(
          { input: this.$refs.autocomplete.value,
            types: this.resultTypes
          },
          (suggestions) => this.locationPredictions = suggestions
      );
    }, 300),
    setPredictions() {
      this.locationPredictions = [];
      if (this.isLoggedIn && this.profile.address && this.profile.address.formattedAddress && this.showUserCityAsPrediction) {
        this.locationPredictions = [ {
          description: this.profile.address.formattedAddress
        }]
      }
      if (this.defaultPredictionsValue.length > 0) this.locationPredictions.push(...this.defaultPredictionsValue);
    },
    chooseOnline() {
      this.location = this._x("En ligne", "Location Filter");
      this.onBlur();
      this.newPlaceSelected("visio");
    },
    chooseLocation: function(location) {
      // this.locationFieldFocused = false;
      this.location = location.description;
      this.onBlur();
      // this.locationSlug = slugify(location.description);
      // this.showPanel = false;
      if(!location.place_id) {
        this.placesService.findPlaceFromQuery({
        query: location.description,
        fields: [
            "place_id",
          ],
      }, (result) => {
        location.place_id = result[0].place_id;
        this.getDetails(location);
      });
      } else this.getDetails(location);

    },
    getDetails(location) {
      this.placesService.getDetails({
        placeId: location.place_id,
        fields: [
            "name",
            "geometry.location",
            "formatted_address",
            "address_components"
          ],
      }, (result) => this.newPlaceSelected(result));
    },
    newPlaceSelected: function(place) {
        var payload = {
          city: "",
          country: "",
          countryCode: "",
          visio: false
        };
        if (place === 'visio') {
          payload.visio = true;
          this.theValue = payload;
          this.editionMode = false;
          return;
        }

        if (place.address_components !== undefined) {
          payload.address_components = place.address_components;
          payload.address_components.forEach(addressComponent => {
            if (addressComponent.types.includes("sublocality"))
              payload.city += addressComponent.long_name;
            else if (addressComponent.types.includes("locality"))
              payload.city +=
                payload.city === ""
                  ? addressComponent.long_name
                  : " , " + addressComponent.long_name;
            else if (addressComponent.types.includes("country")) {
              payload.country = addressComponent.long_name;
              payload.countryCode = addressComponent.short_name;
            }
          });
        }

        if (place.formatted_address !== undefined) {
          payload.formatted_address = place.formatted_address;
          payload.formattedAddress = place.formatted_address;
        }

        if (place.geometry.location !== undefined) {
          payload.lat = place.geometry.location.lat();
          payload.lng = place.geometry.location.lng();
        }

        if (place.name !== undefined) payload.name = place.name;

        if (place.place_id !== undefined) payload.place_id = place.place_id;

        if (place.types !== undefined) payload.types = place.types;

        this.theValue = payload;
        this.editionMode = false;
    }
  },
  beforeDestroy: function() {
    // if (typeof google !== "undefined" && this.ready)
    //   google.maps.event.clearInstanceListeners(this.googleInput);
  },
  watch: {
    theValue: function() {
      this.$emit("update:modelValue", this.theValue);
    },
    modelValue: function() {
      this.theValue = this.modelValue;
      this.setInitialValue();
    },
    onSearch: function() {
      this.$emit("submit", this.theValue);
    },
    ready: function() {
      this.$refs.autocomplete.dispatchEvent(new Event("keydown"));
    },

  }
};
</script>
<style lang="scss">
.pac-container {
  z-index: 10000000 !important;
}
</style>
<style lang="scss" scoped>
.location {
  @include flex;
  position: relative;

  &__address {
    position: relative;
    flex: 1 1 100%;
  }

  &__radius {
    max-width: 80px;
    flex: 0 0 80px;
    margin-left: -1px;

    :deep() {
      select {
        padding-left: $space-xs !important;
        padding-right: 5px !important;
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        background-color: $color-neutral-95;
      }

      svg {
        display: none;
      }
    }
  }

  &--with-radius {
    .location__address {
      position: relative;
      flex: 1 1 100%;

      :deep() {
        input {
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
        }
      }
    }
  }

  &__icon,
  &__remove {
    z-index: 1;
    @include cursor-pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0.6em;
    font-size: 1.2em;
    color: $color-text-lighter;
  }

  &__input {
    @include field;
    padding-left: 38px !important;

    &:focus {
      box-shadow: inset 0 0 0 2px $color-secondary !important;
    }
  }

  &__remove {
    left: auto;
    right: $space-xs;
    line-height: 0px;
    font-size: 1.4em;
    color: $color-text;

    svg {
      max-width: $space-m;
    }
  }
}
.item__panel {
  left: 0px;
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: 9999;
  visibility: visible;
  opacity: 1;

  .panel {
    position: absolute;
    left: 0px;
    top: 100%;
    z-index: 1;
    background: $color-neutral-100;
    @include border-radius;
    margin-top: $space-xs;
    max-height: calc(100vh - 220px);
    overflow-x: hidden !important;
    overflow-y: auto !important;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.13);
    border: $border;
    @include text-body;
    min-width: 300px;
    max-width: 500px;
    padding: $space-s $space-xs;

    &__close {
      position: absolute;
      top: $space-xs;
      right: $space-xs;
      z-index: 10;
    }

    &__header {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
    }

    &__tabs {
      background-color: $color-neutral-90;
      border-radius: 100px;
      padding-left: 4px;
      padding-right: 4px;
      display: flex;
      margin-bottom: $space-s;

      .tabs__tab {
        border-radius: 100px;
        @include text-body-black;
        border: none;
        padding: 5px 14px;
        margin-top: 4px;
        margin-bottom: 4px;
        position: relative;
        outline: none;
        cursor: pointer;

        &:hover {
          background-color: $color-neutral-95;
        }

        &--active {
          background-color: $color-neutral-100 !important;
        }
      }
    }

    .workspace {
      &__header {
        @include flex;
        justify-content: center;
        align-items: center;
        margin-bottom: $space-m;
        @include text-large-black;

        > * {
          margin-left: $space-xxs;
          margin-right: $space-xxs;
        }
      }

      &__cols {
        @include flex;
      }
    }

    &__size-large {
      max-width: 100%;
      width: 100%;
    }

    &__lines > div,
    &__line {
      padding: $space-xs;
      @include border-radius;
      user-select: none;
      position: relative;
      margin-bottom: $space-xxs;

      &--selected {
        @include text-body-black;
        background-color: $color-neutral-90 !important;
      }

      &:hover {
        background-color: $color-neutral-95;
        cursor: pointer;
      }

      &.no-select {
        background-color: transparent;
        cursor: initial !important;
      }

      .line {
        &__icon {
          position: absolute;
          right: $space-xs;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
.panel__online {
  margin-bottom: 0;
}
.divider {
  color: $color-neutral-90;
  border: none;
  border-top: 1px solid $color-neutral-85;
  background-color: $color-neutral-90;
  margin: 0 $space-xs $space-xs;
}
</style>
