import { Swiper, SwiperSlide } from 'swiper/vue';

  // Import Swiper styles
import 'swiper/css';

// register();

export default defineNuxtPlugin((NuxtApp) => {
  NuxtApp.vueApp.component('swiper', Swiper);
  NuxtApp.vueApp.component('swiper-slide', SwiperSlide);
});