export default {
  data() {
    return {
      mailProviderLabel: ''
    }
  },
  methods: {
    getSniperLink(email) {
      if (!email) return;
      if (email.includes('@gmail') || email.includes('@google') || email.includes('@makesense.org')) {
        this.mailProviderLabel = 'Gmail';
        return `https://mail.google.com/mail/u/0/#search/from%3A(events.noreply%40makesense.org)+in%3Aanywhere+newer_than%3A1d`;
      } else if (email.includes('@outlook')) {
        this.mailProviderLabel = 'Outlook';
        return `https://outlook.live.com/mail/0/`;
      } else if (email.includes('@yahoo')) {
        this.mailProviderLabel = 'Yahoo Mail';
        return `https://mail.yahoo.com/d/search/keyword=from%253Aevents.noreply%2540makesense.org`;
      } else if (email.includes('@protonmail')) {
        this.mailProviderLabel = 'Protonmail';
        return `https://mail.proton.me/u/0/all-mail#from=events.noreply%40makesense.org`;
      } else if (email.includes('@icloud')) {
        this.mailProviderLabel = 'iCloud';
        return `https://www.icloud.com/mail/`;
      }
    }
  }
};
