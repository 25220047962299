export const state = () => ({
  name: null,
  advert: null,
});

export const getters = {
  get: state => {
    return state.advert;
  },
};

export const mutations = {
  SET(state, payload) {
    state.advert = payload;
  },
};

export const actions = {
  // async init(state) {
  //   const advert = await this.$api.Advert.live();
  //   state.commit('SET', advert);
  // },
  set(state, payload) {
    state.commit('SET', payload);
  }
};
