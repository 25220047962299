<template>
  <Teaser
    v-if="article && article.data"
    :variant="variant"
    :title="article.data.title"
    :text="article.data.descriptionCourte"
    :image="article.data.image"
    :to="url"
    :tagLabel="article.data.media"
    :tagIcon="article.data.media"
    tagColor="parsley"
  />
  <mks-loading type="standalone" v-else></mks-loading>
</template>
<script>
import Teaser from '../../molecules/Teaser.vue'
import {useAsyncData, useNuxtApp} from "nuxt/app";
export default defineNuxtComponent({
  components: {Teaser},
  props: {
    content: {
      required: true
    },
    variant: {
      type: String,
      default: 'text' // "text", "spolight",
    }
  },
  data() {
    return {
      article: null
    }
  },
  async setup(props) {
    const NuxtApp = useNuxtApp();
    let article = null;
    if (props.content && props.content.data) {
      article = props.content;
    }
    else if (props.content && props.content.value && props.content.value.data) {
      article = props.content.value;
    }
    else if (props.content && props.content.id && props.content.model){
      try {
        // key is required to prevent bug that shows wrong article
        const builderResponse = await useAsyncData(props.content.id, () => NuxtApp.$builder.getRef(props.content));
        article = builderResponse.data;
      } catch (e) {
        console.log(e);
      }
    }

    return {
      article
    };
  },
  computed: {
    topic() {
      return this.article?.data?.thematique;
    },
    url() {
      const category = this.topic && this.topic.value && this.topic.value.data.slug || 'category';
      // if (this.topic && this.topic.value && this.topic.value.data) { 
      //   return `/media/${this.topic.value.data.slug}/${this.article.data.slug}`
      // }
      return `/media/${category}/${this.article.data.slug}`
    }
  }
})
</script>
<style lang="scss">
.article-teaser {
  // max-width: 400px;
}
</style>