<template>
  <div class="filters">
    <mks-button
      class="filter__mobile-toggle"
      variant="ghost"
      type="blue-dianne"
      size="small"
      tag="button"
      icon="filter"
      @click="toggleFilters"
    >
      <mks-text size="small" weight="bold" v-if="!showFilters">
        Filtrer les événements
      </mks-text>
      <mks-text size="small" weight="bold" v-else>
        Cacher les filtres
      </mks-text>
    </mks-button>
        <div
          v-if="Array.isArray(show) && show.length <= 3"
          class="filter__group mks-grid-auto-md mks-grid-gap-xs"
          :class="{ visible: showFilters }"
        >
          <mks-field
            v-if="show === 'all' || show.includes('topic')"
            name="topic"
            type="select"
            :options="topicsInLocale"
            v-model="selected.topic"
            :placeholder="__('Tous les topics')"
          ></mks-field>
          <mks-field
            v-if="
              (show === 'all' || show.includes('date'))
            "
            name="date"
            type="select"
            :options="dateBetween && dateBetween.length > 0 ? datesOptionsLimitedTime : datesOptions"
            v-model="selected.date"
            :placeholder="_x('Toutes les dates', 'Placeholder filtre dates')"
          ></mks-field>
          <FieldLocation
            v-if="show === 'all' || show.includes('address')"
            id="address"
            name="address"
            :placeholder="_x('Près de', 'Placeholder')"
            v-model="selected.address"
          />
          <mks-field
            v-if="show.includes('search')"
            type="search"
            name="search"
            v-model="selected.search"
            :placeholder="__('Rechercher un événement')"
          ></mks-field>
        </div>
        <div
          v-else
          class="filter__group mks-grid-auto-lg mks-grid-auto-sm@md mks-grid-gap-xs"
          :class="{ visible: showFilters }"
        >
          <mks-field
            v-if="show === 'all' || show.includes('search')"
            type="search"
            name="search"
            v-model="selected.search"
            :placeholder="__('Rechercher un événement')"
          ></mks-field>
          <FieldLocation
            v-if="show === 'all' || show.includes('address')"
            id="address"
            name="address"
            :placeholder="_x('Près de', 'Placeholder')"
            v-model="selected.address"
          />
          <mks-field
            v-if="show === 'all' || show.includes('date')"
            name="date"
            type="select"
            :options="datesOptions"
            v-model="selected.date"
            :placeholder="_x('Toutes les dates', 'Placeholder filtre dates')"
          ></mks-field>
          <mks-field
            v-if="show === 'all' || show.includes('topic')"
            name="topic"
            type="select"
            :options="topicsInLocale"
            v-model="selected.topic"
            :placeholder="__('Tous les topics')"
          ></mks-field>
          <!-- <mks-field
        v-if="show === 'all' || show.includes('type')"
        name="type"
        type="select"
        :options="typesInLocale"
        v-model="selected.type"
        :placeholder="__('Tous les types')"
      ></mks-field> -->
          
          
        </div>
        <mks-spacer
          inline
          align="center"
          distribute="equal"
          class="filters__results"
          :class="{ visible: showFilters }"
          v-if="$slots['results'] || $slots['quicksearch']"
        >
          <slot name="quicksearch"></slot>
          <template v-if="$slots['results']">
            <mks-text size="small" color="light">
              <!-- <mks-icon type="list" color="inherit" size="18"></mks-icon>&nbsp; -->
              <slot name="results"></slot>
              <mks-button
                class="button-reinit"
                size="small"
                @click="resetFilters"
                v-if="hasQuery"
                type="neutral"
              >
                {{ __("Réinitialiser") }}
                <mks-icon
                  type="x"
                  size="14"
                  alt="Réinitialiser la recherche"
                ></mks-icon>
              </mks-button>
            </mks-text>
          </template>
        </mks-spacer>
  </div>
</template>
<script>
import FieldLocation from "@/components/molecules/FieldLocation.vue";
import MksField from "@DS/components/Field.vue";
export default {
  components: {
    FieldLocation,
    MksField
  },
  props: ["modelValue", "show", "dateBetween"],
  data() {
    return {
      showExtras: false,
      momentums: [],
      topics: [],
      types: [],
      hotspots: [],
      selected: {},
      showFilters: false
      // this.$route.params.slug
    };
  },
  computed: {
    hasQuery() {
      return (
        Object.keys(this.selected).length > 0 &&
        (this.selected.date ||
          this.selected.from ||
          this.selected.to ||
          this.selected.topic ||
          this.selected.type ||
          this.selected.address ||
          this.selected.countryCode ||
          this.selected.lat ||
          this.selected.lng ||
          this.selected.momentum ||
          this.selected.hotspot ||
          this.selected.search ||
          this.selected.makesense ||
          this.selected.visio)
      );
    },
    datesOptions: function() {
      return [
        {
          label: this._x("Toutes les dates", "Options date : Nom"),
          value: ""
        },
        {
          label: this._x("Aujourd'hui", "Options date : Nom"),
          value: {
            track: "Aujourd'hui",
            from: this.$dayjs().format(
              this._x("YYYY-MM-DD", "Filtre date : Format valeur des dates")
            ),
            to: this.$dayjs().format(
              this._x("YYYY-MM-DD", "Filtre date : Format valeur des dates")
            )
          }
        },
        {
          // this.$dayjs().add(1, "days")
          label: this._x("Demain", "Options date : Nom"),
          value: {
            track: "Demain",
            from: this.$dayjs()
              .add(1, "day")
              .format(
                this._x("YYYY-MM-DD", "Filtre date : Format valeur des dates")
              ),
            to: this.$dayjs()
              .add(1, "day")
              .format(
                this._x("YYYY-MM-DD", "Filtre date : Format valeur des dates")
              )
          }
        },
        {
          label: this._x("Ce week-end", "Options date : Nom"),
          value: {
            track: "Ce week-end",
            from: this.$dayjs()
              .weekday(5)
              .format(
                this._x("YYYY-MM-DD", "Filtre date : Format valeur des dates")
              ),
            to: this.$dayjs()
              .weekday(6)
              .format(
                this._x("YYYY-MM-DD", "Filtre date : Format valeur des dates")
              )
          }
        },
        {
          label: this._x("Cette semaine", "Options date : Nom"),
          value: {
            track: "Cette semaine",
            from: this.$dayjs().format(
              this._x("YYYY-MM-DD", "Filtre date : Format valeur des dates")
            ),
            to: this.$dayjs()
              .weekday(6)
              .endOf("day")
              .format(
                this._x("YYYY-MM-DD", "Filtre date : Format valeur des dates")
              )
          }
        },
        {
          label: this._x("Semaine suivante", "Options date : Nom"),
          value: {
            track: "Semaine suivante",
            from: this.$dayjs()
              .weekday(7)
              .format(
                this._x("YYYY-MM-DD", "Filtre date : Format valeur des dates")
              ),
            to: this.$dayjs()
              .weekday(13)
              .format(
                this._x("YYYY-MM-DD", "Filtre date : Format valeur des dates")
              )
          }
        },
        {
          label: this._x("Ce mois", "Options date : Nom"),
          value: {
            track: "Ce mois",
            from: this.$dayjs().format(
              this._x("YYYY-MM-DD", "Filtre date : Format valeur des dates")
            ),
            to: this.$dayjs()
              .endOf("month")
              .format(
                this._x("YYYY-MM-DD", "Filtre date : Format valeur des dates")
              )
          }
        },
        {
          label: this._x("Mois prochain", "Options date : Nom"),
          value: {
            track: "Mois prochain",
            from: this.$dayjs()
              .add(1, "month")
              .startOf("month")
              .format(
                this._x("YYYY-MM-DD", "Filtre date : Format valeur des dates")
              ),
            to: this.$dayjs()
              .add(1, "month")
              .endOf("month")
              .format(
                this._x("YYYY-MM-DD", "Filtre date : Format valeur des dates")
              )
          }
        }
        // {
        //   name: this._x('Choisissez une date…', 'Options date : Nom'),
        //   value: null
        // }
      ];
    },
    datesOptionsLimitedTime: function() {
      // specially for thedaysafter
      if (this.dateBetween.length === 0) return [];

      const date1 = this.$dayjs(this.dateBetween[0]);
      const date2 = this.$dayjs(this.dateBetween[1]);
      const diff = date2.diff(date1, "day");
      let dates = [
        {
          label: this._x("Toutes les dates", "Options date : Nom"),
          value: ""
        }
      ];

      dates.push({
        label:
          this._x("Avant le", "Options date : Nom") +
          " " +
          date1.format("D") +
          this._x(this._x(date1.format(" MMMM")), "Options date : Nom"),
        value: {
          from: this.$dayjs().format(
            this._x("YYYY-MM-DD", "Filtre date : Format valeur des dates")
          ),
          to: date1.format(
            this._x("YYYY-MM-DD", "Filtre date : Format valeur des dates")
          )
        }
      });
      for (let i = 0; i <= diff; i++) {
        dates.push({
          label:
            date1.add(i, "day").format("D") +
            " " +
            this._x(
              this._x(date1.add(i, "day").format("MMMM")),
              "Options date : Nom"
            ),
          value: {
            from: date1
              .add(i, "day")
              .format(
                this._x("YYYY-MM-DD", "Filtre date : Format valeur des dates")
              ),
            to: date1
              .add(i, "day")
              .format(
                this._x("YYYY-MM-DD", "Filtre date : Format valeur des dates")
              )
          }
        });
      }
      dates.push({
        label:
          this._x("Après le", "Options date : Nom") +
          " " +
          date2.format("D") +
          this._x(this._x(date2.format(" MMMM")), "Options date : Nom"),
        value: {
          from: date2.format(
            this._x("YYYY-MM-DD", "Filtre date : Format valeur des dates")
          ),
          to: this.$dayjs("2100-01-01").format(
            this._x("YYYY-MM-DD", "Filtre date : Format valeur des dates")
          )
        }
      });

      return dates;
    },
    momentumsInLocale: function() {
      return this.mapTaxo(this.momentums, this.__("Tous les momentums"));
    },
    topicsInLocale: function() {
      return this.mapTaxo(this.topics, this.__("Tous les topics"));
    },
    typesInLocale: function() {
      return this.mapTaxo(this.types, this.__("Tous les types"));
    },
    hotspostInLocale: function() {
      return this.mapTaxo(this.hotspots, this.__("Tous les hotspots"));
    }
  },
  watch: {
    "selected.date": {
      handler() {
        if (this.selected.date) {
          this.selected.from = this.selected.date.from;
          this.selected.to = this.selected.date.to;
          this.$emit("update:modelValue", this.selected);
          if (this.selected.date.from || this.selected.date.to) {
            this.track("date", this.selected.date.track);
          }
        }
      },
      deep: true
    },
    "selected.address": {
      handler(val) {
        this.selected.city = this.selected.address.city;
        this.selected.countryCode = this.selected.address.countryCode;
        this.selected.lat = this.selected.address.lat;
        this.selected.lng = this.selected.address.lng;
        this.selected.formattedAddress = this.selected.address.formattedAddress;
        this.selected.visio = this.selected.address.visio;
        if (val.visio === true) {
          this.selected.visio = true;
          this.track("address", "visio");
          this.$emit("update:modelValue", this.selected);
          return;
        }
        this.track("address", this.selected.address.formattedAddress);
      },
      deep: true
    },
    "selected.hotspot": {
      handler(val) {
        this.track("hotspot", val);
      },
      deep: true
    },
    "selected.topic": {
      handler(val) {
        this.track("topic", val);
      },
      deep: true
    },
    "selected.type": {
      handler(val) {
        this.track("type", val);
      },
      deep: true
    },
    "selected.momentum": {
      handler(val) {
        this.track("momentum", val);
      },
      deep: true
    },
    "selected.search": {
      handler(val) {
        this.track("keyword", val);
      },
      deep: true
    },
    selected(val, prev) {
      if (!val || val == prev) return;
      this.$emit("update:modelValue", this.selected);
    }
  },
  created() {
    this.selected = this.modelValue || {
      momentum: "",
      date: "",
      from: "",
      to: "",
      topic: "",
      pillar: "",
      address: "",
      countryCode: "",
      city: "",
      lat: "",
      lng: "",
      type: "",
      hotspot: "",
      search: "",
      visio: ""
    };
    this.getFilterContent();
  },
  methods: {
    toggleFilters() {
      this.showFilters = !this.showFilters;
    },
    track(filter, data) {
      if (!data || data === "") return;

      this.$store.dispatch("analytics/track", {
        event: "Front - Use filters",
        data: {
          label: "Filter by",
          filter,
          value: data
        }
      });
    },
    getFilterContent() {
      this.$api.Taxonomy.query({ limit: 0, hidden: false }).then(taxos => {
        // this.types = [];
        // this.topics = [];
        // this.momentums = [];
        taxos.data.forEach(t => {
          if (t.type === "momentum") {
            this.momentums.push({ value: t.slug, label: t.name });
          } else if (t.type === "topic") {
            this.topics.push({ value: t.slug, label: t.name });
          } else if (t.type === "type") {
            this.types.push({ value: t.slug, label: t.name });
          }
        });
      });

      this.$api.Hotspot.query({ limit: 0 }).then(hotspots => {
        this.hotspots = hotspots.data.map(h => {
          return { value: h.slug, label: h.name };
        });
      });
    },
    mapTaxo: function(taxo, label) {
      let val = taxo.map(t => {
        return {
          value: (t.value && t.value[this.lang]) || t.value,
          label: (t.label && t.label[this.lang]) || t.label
        };
      });
      val.unshift({ value: "", label: label });
      return val;
    },
    resetFilters() {
      this.$emit("reset");
    }
  }
};
</script>
<style lang="scss" scoped>
.filters .mks-grid-gap-xs {
  --grid-gap: var(--space-xs);
}

.filter__group,
.filters__results {
  &:not(.visible) {
    @include breakpoint("small", "max") {
      display: none;
    }
  }
}

.filters__results{
  & > :only-child {
    margin-left: auto;
  }
}

.filter__mobile-toggle {
  display: none;
  @include breakpoint("small", "max") {
    display: initial;

    &:hover span{
      color: $color-neutral-100 !important;
    }
  }
}

.filter__group:nth-child(2) {
  margin-top: $space-s;
}

.filters__results {
  padding-left: $space-xxs;
  padding-top: $space-xs;
}

input {
}

.filters {
  width: 100%;
  .button-icon {
    width: $space-xl;
    height: $space-xl;
    min-width: $space-xl;
  }
  .button-reinit {
    @include text-small-semibold;
    // background: none;
    // color: $color-text;
    // border: none;
    @include inner-space("xs", "x");
    @include inner-space("xxxs", "y");

    // &:focus {
    //   @include focus($color-secondary);
    // }
  }
  :deep() {
    .field--select:focus,
    .field__input:focus {
      box-shadow: inset 0 0 0 2px var(--color-secondary) !important;
    }
    .control__icon {
      transition: all 0.3s ease;
      transform: rotate(0deg);
      &--rotate {
        transform: rotate(180deg);
      }
    }
  }
}
.slide-top {

  &-enter-active, &-leave-active {
    transition: transform .3s / 2;
  }

  &-enter-active {
    transition-delay: .3s;
  }

  &-enter, &-leave-to {
    transform: translateY(100%);
  }
}
</style>
