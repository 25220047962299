import DesignSystem from "@DS";
import Atoms from "@DS/components/atoms.js";
import MksSpacer from "@DS/components/Spacer.vue";
import MksSpacerItem from "@DS/components/SpacerItem.vue";

export default defineNuxtPlugin((NuxtApp) => {
  NuxtApp.vueApp.use(DesignSystem, {theme: "chiche"});
  NuxtApp.vueApp.use(Atoms);
  NuxtApp.vueApp.component("MksSpacer", MksSpacer);
  NuxtApp.vueApp.component("MksSpacerItem", MksSpacerItem);

  NuxtApp.vueApp.mixin({
    async asyncData(props) {
      console.log("asyncdata mixin", props);
    }
  });
})
