<template>
  <div v-html="code"></div>
</template>
<script>
export default {
  props: {
    code: String
  },
  data() {
    return {
      scriptsInserted: [],
      scriptsRun: []
    }
  },
  mounted() {
    const div = document.createElement('div');
    div.innerHTML = this.code;

    const scripts = div.getElementsByTagName('script');
    for (let i = 0; i < scripts.length; i++) {
      const script = scripts[i];
      if (script.src) {
        if (this.scriptsInserted.includes(script.src)) {
          continue;
        }
        this.scriptsInserted.push(script.src);
        const newScript = document.createElement('script');
        newScript.async = true;
        newScript.src = script.src;
        document.head.appendChild(newScript);
      }
      else if (
        !script.type ||
        [
          'text/javascript',
          'application/javascript',
          'application/ecmascript',
        ].includes(script.type)
      ) {
        if (this.scriptsRun.includes(script.innerText)) {
          continue;
        }
        try {
          this.scriptsRun.push(script.innerText);
          new Function(script.innerText)();
        } catch (error) {
          console.warn('`CustomCode`: Error running script:', error);
        }
      }
    }
  }
}
</script>