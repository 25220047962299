<template>
  <mks-text class="event-metas" :color="color" size="small" weight="semibold">
    <span v-if="event.visio && showLocation">
      <mks-icon type="video" size="18" /> &nbsp;{{__('En ligne')}}
    </span>
    <span v-else-if="event.address && event.address[lang] && showLocation">
      <mks-icon type="map-pin" size="18"/> &nbsp;{{event.address[lang].city}}
    </span>
    <span v-if="event.date && showDate">
      <mks-icon type="clock" size="18"/> &nbsp;{{ dateFormatted }}
    </span>
    <span v-if="event.date && event.endDate && showDuration">
      <mks-icon type="clock" size="18"></mks-icon> &nbsp;{{ getDurationText(event.date, event.endDate) }}
    </span>
  </mks-text>
</template>
<script>
import dateMixin from '@/mixins/date'
export default {
  mixins: [dateMixin],
  props: {
    event: {
      type: Object,
      required: true
    },
    color: {
      type: String,
      default: 'inherit'
    },
    showLocation: {
      type: Boolean,
      default: true
    },
    showDate: {
      type:Boolean,
      default: false
    },
    showDuration: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    dateFormatted() {
      return this.relativeDate(this.event.date, true, false);
    }
  }
}
</script>
<style lang="scss">
.event-metas {
  & > * {
    margin-right: $space-xs;
  }
}
</style>