
import * as ipxRuntime$VjUzDENllf from '/usr/src/app/node_modules/@nuxt/image/dist/runtime/providers/ipx'
import * as bunnyRuntime$YWHb4e6jkI from '/usr/src/app/node_modules/@nuxt/image/dist/runtime/providers/bunny'
import * as noneRuntime$h6hg9PtjB2 from '/usr/src/app/node_modules/@nuxt/image/dist/runtime/providers/none'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "bunny",
  "domains": [
    "france.makesense.org",
    "makesense.org",
    "amazonaws.com",
    "s3.eu-west-1.amazonaws.com",
    "cdn.builder.io",
    "scontent.cdninstagram.com"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$VjUzDENllf, defaults: {"maxAge":31536000} },
  ['bunny']: { provider: bunnyRuntime$YWHb4e6jkI, defaults: {"baseURL":"https://chiche.b-cdn.net"} },
  ['none']: { provider: noneRuntime$h6hg9PtjB2, defaults: {} }
}
        