import dayjs from "dayjs";
import "dayjs/locale/fr";
import "dayjs/locale/en";
import "dayjs/locale/es";
import pluginIsBetween from "dayjs/plugin/isBetween";
import pluginRelativeTime from "dayjs/plugin/relativeTime";
import pluginTimezone from "dayjs/plugin/timezone";
import pluginUtc from "dayjs/plugin/utc";
import pluginWeekday from "dayjs/plugin/weekday";

dayjs.extend(pluginIsBetween);
dayjs.extend(pluginRelativeTime);
dayjs.extend(pluginTimezone);
dayjs.extend(pluginUtc);
dayjs.extend(pluginWeekday);

export default defineNuxtPlugin((NuxtApp) => {
  const locale = NuxtApp.$i18n.locale && NuxtApp.$i18n.locale.value ? NuxtApp.$i18n.locale.value : NuxtApp.$i18n.locale;
  dayjs.locale(locale);
  return {
    provide: {
      dayjs
    }
  }
});

