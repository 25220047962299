import { defineAsyncComponent } from "vue";

export default defineNuxtPlugin((NuxtApp) => {
  let tippyImportPromise = null;

  const importTippy = async () => {
    const VueTippy = await import("vue-tippy");
    NuxtApp.vueApp.use(VueTippy.default, {
      directive: "tippy",
      component: "tippy-alt",
      defaultProps: {
        arrow: false,
        animation: 'shift-away',
        theme: 'light-border',
        flipOnUpdate: true
      },
    });
    return VueTippy.Tippy;
  }

  const asyncTippy = defineAsyncComponent(async () => {
    if (!tippyImportPromise) {
      tippyImportPromise = importTippy();
    }
    const tippyComponent = await tippyImportPromise;
    return tippyComponent;
  })

  NuxtApp.vueApp.component('tippy', asyncTippy);

})
