<template>
    <MksButton v-bind="$attrs">
        <span v-html="label"></span>
    </MksButton>
</template>
<script>
export default {
    props: {
        label: String
    }
}
</script>