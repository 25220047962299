<template>
  <mks-spacer
    class="available-tickets__form"
    spacing="xxxs"
  >
    <mks-text
      size="small"
      color="light"
      v-if="event && event.maxAttendees && availableTickets > 0 && availableTickets < 10"
      >{{
        _x(
          "${nbPlacesAvailable} place(s) disponible(s)",
          "Texte nombre de places disponible",
          { nbPlacesAvailable: availableTickets }
        )
      }}</mks-text
    >
  </mks-spacer>
</template>
<script>
export default {
  props: {
    event: {
      type: Object,
      required: true
    },
    showHeader: {
      type: Boolean,
      required: false
    },
  },
  computed: {
    availableTickets() {
      // if (this.preview) return;
      return (
        parseInt(this.event.maxAttendees) -
        parseInt((this.event.attendees && this.event.attendees.length) || 0)
      );
    },
  }
}
</script>
<style lang="scss">
.available-tickets__form {
  padding-top: $space-s;
}
</style>