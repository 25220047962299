import VueMatomo from 'vue-matomo'

export default defineNuxtPlugin((nuxtApp) => {
  const $env = nuxtApp.$env;
  if ($env.matomo) {
    nuxtApp.vueApp.use(VueMatomo, {
      siteId: $env.matomo.siteId,
      trackerUrl: $env.matomo.trackerUrl,
      trackerScriptUrl: $env.matomo.trackerScriptUrl,
      router: nuxtApp.$router,
      enableLinkTracking: true,
      requireConsent: false,
      trackInitialView: true,
      disableCookies: false,
      requireCookieConsent: false,
    })
  }
})