<template>
  <component 
    :is="tag" 
    :class="{
      'section': true, 
      'mks-section-container': noInnerWrapper && !smallContent,
      'mks-section-container-small': noInnerWrapper && smallContent,
      'section--radius-top': radius === 'top' || radius === 'both',
      'section--radius-bottom': radius === 'bottom' || radius === 'both',
      'section--radius-bean': radius === 'bean',
      'section--v-padding': !noVerticalPadding,
      'section--l-padding-only': noRightPadding,
    }"
    :style="style"
  >

    <svg v-if="radius === 'bean'" class="section__bean-svg" xmlns="http://www.w3.org/2000/svg" width="1440" height="941" fill="none"><rect width="2027.55" height="900" x="-77.229" y="-40" fill="#24673A" rx="531.439" transform="rotate(4.575 -77.229 -40)"/></svg>

    <slot v-if="noInnerWrapper"></slot>
    <div v-else :class="{
      'section__inner': true, 
      'mks-section-container-small': !noHorizontalPadding && !noRightPadding && smallContent,
      'mks-section-container': !noHorizontalPadding && !noRightPadding && !smallContent
    }">
      <slot></slot>
    </div>
  </component>
</template>
<script>
import colorsMixin from '@/mixins/colors';
export default {
  mixins: [colorsMixin],
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    background: {
      type: String,
      default: '#ffffff'
    },
    color: {
      type: String
    },
    noRightPadding: {
      type: Boolean,
      default: false
    },
    noHorizontalPadding: {
      type: Boolean, 
      default: false
    },
    noVerticalPadding: {
      type: Boolean, 
      default: false
    },
    noInnerWrapper: {
      type: Boolean, 
      default: false
    },
    radius: {
      type: [Boolean, String],
      default: 'none' // 'top', 'bottom', 'both', 'bean'
    },
    smallContent: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    style() {
      let style = '';
      if (this.background) {
        style += this.getColorStyles('--section-background', this.background);
      }
      if (this.color) {
        style += this.getColorStyles('--section-color', this.color);
      }
      return style;
    }
  }
}
</script>
<style lang="scss">
$radius-desktop: 70px;
$radius-mobile: 45px;

.section {
  background: var(--section-background);
  color: var(--section-color);
  position: relative;
  overflow: visible;

  &--radius-bean {
    // -webkit-mask-image: url('/assets/images/bean-svg.svg');
    // mask-image: url('/assets/images/bean-svg.svg');
    // mask-position: 30% 0;
    background: transparent;

    .section__inner {
      padding-top: $space-l;
      @include breakpoint('small') {
        padding-top: $space-xl;
      }
    }
  }

  &--v-padding {
    padding-top: $space-l;
    padding-bottom: $space-l;

    @include breakpoint('small') {
      padding-top: $space-xl;
      padding-bottom: $space-xl;
    }
  }

  &--l-padding-only {
    // calcultated in layout
    padding-left: var(--margin-left);
    
    .mks-carousel {
      padding-right: var(--margin-left);
      
      @include breakpoint('small') {
        padding-right: 30px;
      }
    }
  }

  &--radius-top {
    border-top-left-radius: $radius-mobile;
    border-top-right-radius: $radius-mobile;
    padding-top: calc($radius-mobile / 1.5);

    @include breakpoint('small') {
      padding-top: $radius-desktop - 10px;
      border-top-left-radius: $radius-desktop;
      border-top-right-radius: $radius-desktop;
    }
  }
  &--radius-bottom {
    border-bottom-left-radius: $radius-mobile;
    border-bottom-right-radius: $radius-mobile;
    padding-bottom: calc($radius-mobile / 1.5);

    @include breakpoint('small') {
      padding-bottom: $radius-desktop - 10px;
      border-bottom-left-radius: $radius-desktop;
      border-bottom-right-radius: $radius-desktop;
    }
  }
}
.section__bean-svg {
  position: absolute;
  z-index: -1;
  overflow: visible;
  left: -50%;
  right: 0;

  rect {
    fill: var(--section-background);
    
  }

  @include breakpoint('small') {
    left: -35%;
  }

  @include breakpoint('medium') {
    left: -20%;
  }

  @include breakpoint('large') {
    left: 0;
    right: 0;
    width: 100%;
  }

  @include breakpoint('xlarge') {
    top: -20px;
    rect {
      width: 110%;
    }
  }
}
</style>